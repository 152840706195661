import styled from "styled-components";

export const List = styled.ul`
  width: 100%;
  max-height: 100%;

  display: flex;
  flex-wrap: wrap;
  flex-direction: row;

  justify-content: space-between;
  align-items: flex-start;
  align-content: space-between;

  //flex-flow: column wrap;
  //flex-flow: wrap;
`;

export const SupplierStockButtonContainer = styled.div`
  position: relative;
  width: 100%;
  float: left;
  margin-top: ${({ theme }) => theme.header.height.percent / 3}%;
  margin-top: -webkit-calc(${({ theme }) => theme.header.height.pixel / 3}px);
  margin-top: -moz-calc(${({ theme }) => theme.header.height.pixel / 3}px);
  margin-top: calc(${({ theme }) => theme.header.height.pixel / 3}px);

  @media (min-width: 1100px) {
    width: auto;
  }
`;

export const SupplierStockButtonButton = styled.button`
  -webkit-transition: background-color 0.25s;
  -moz-transition: background-color 0.25s;
  -o-transition: background-color 0.25s;
  transition: background-color 0.25s;

  position: relative;
  background-color: ${({ theme }) =>
    theme.body.search.buttonSupplierStock.background.default};
  color: ${({ theme }) => theme.body.search.buttonSupplierStock.text.default};
  float: none;
  margin: 0 auto;

  padding: 9px 20px;
  -moz-border-radius: 100px;
  -webkit-border-radius: 100px;
  -khtml-border-radius: 100px;
  border-radius: 100px;
  font-size: 9em;
  line-height: 2em;
  font-weight: 700;
  //text-transform: uppercase;
  &:hover {
    background-color: ${({ theme }) =>
      theme.body.search.buttonSupplierStock.background.hover};
  }
  &:active {
    background-color: ${({ theme }) =>
      theme.body.search.buttonSupplierStock.background.active};
  }
`;

export const ErrorContainer = styled.div`
  position: absolute;
  //float: none;
  //clear: both;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 250px;
  pointer-events: auto;
  overflow: auto;
  //background: green;
  margin: -125px 0 0 -50%;
`;

export const ErrorIcon = styled.div`
  position: absolute;
  float: none;
  clear: both;
  top: 50px;
  left: 50%;
  width: 30px;
  display: inline-block;

  margin-top: 0;
  margin-left: -60px;
  color: ${({ theme }) => theme.sidebar.background};
  font-size: 120px;
  line-height: 120px;
  font-family: "${({ theme }) => theme.font.icon}";
  font-weight: 700;
  text-align: center;
  &::before {
    width: 100%;
    height: 100%;
    content: '\\f071'};
  }
`;

export const FilterIcon = styled.div`
  position: absolute;
  float: none;
  clear: both;
  top: 50px;
  left: 50%;
  width: 30px;
  display: inline-block;

  margin-top: 0;
  margin-left: -60px;
  color: ${({ theme }) => theme.sidebar.background};
  font-size: 120px;
  line-height: 120px;
  font-family: "${({ theme }) => theme.font.icon}";
  font-weight: 700;
  text-align: center;
  &::before {
    width: 100%;
    height: 100%;
    content: '\\f0b0'};
  }
`;

export const EmptyIcon = styled.div`
  position: absolute;
  float: none;
  clear: both;
  top: 50px;
  left: 50%;
  width: 30px;
  display: inline-block;

  margin-top: 0;
  margin-left: -60px;
  color: ${({ theme }) => theme.sidebar.background};
  font-size: 120px;
  line-height: 120px;
  font-family: "${({ theme }) => theme.font.icon}";
  font-weight: 700;
  text-align: center;
  &::before {
    width: 100%;
    height: 100%;
    content: '\\f656'};
  }
`;

export const ErrorMessage = styled.div`
  position: absolute;
  float: none;
  clear: both;
  top: 200px;
  width: 90%;
  padding: 0 5%;
  text-align: center;
  font-size: 12em;
  font-weight: 700;
`;
