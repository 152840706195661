import React from "react";
import { connect } from "react-redux";

import { Container, List } from "./AlertsStyle";

import Expire from "../../component/Expire";
import Alert from "./Alert/Alert";

import { alertRemove } from "../../redux/store/alert";

class Alerts extends React.Component {
  handleDismiss = (id) => {
    this.props.dispatch(alertRemove(id));
  };

  getAlerts = () => {
    if (this.props.list) {
      return this.props.list.map((i) => {
        if (i.expire) {
          return (
            <Expire type={"alert"} key={i.id} delay={i.expire}>
              <Alert handleDismiss={this.handleDismiss} {...i} />
            </Expire>
          );
        }
        if (i.dismiss) {
          return <Alert key={i.id} handleDismiss={this.handleDismiss} {...i} />;
        }
        return <Alert key={i.id} {...i} />;
      });
    }
  };

  render() {
    return (
      <Container>
        <List>{this.getAlerts()}</List>
      </Container>
    );
  }
}

export default connect(
  ({ alert }) => ({
    list: alert.list,
  }),
  null
)(Alerts);

//export const Info = () => {};

//export const Success = () => {};

//export const Error = () => {};

/*

    position: relative;
    float: left;
    clear: none;
    display: block;
    width: auto;
    height: auto;
    margin: 0;
    padding: 0;
    -webkit-touch-callout: none; -webkit-user-select: none; -khtml-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none;
    pointer-events: none;

    */
