import styled from "styled-components";

export const Container = styled.div`
  float: none;
  clear: both;
  width: 100%;
  height: ${({ theme }) => theme.header.height.percent}%;
  height: -webkit-calc(${({ theme }) => theme.header.height.pixel}px);
  height: -moz-calc(${({ theme }) => theme.header.height.pixel}px);
  height: calc(${({ theme }) => theme.header.height.pixel}px);
  white-space: nowrap;
  &:before {
    content: "";
    display: inline-block;
    width: 0;
    height: 100%;
    /*margin-right: -0.25em;*/
    vertical-align: middle;
  }
`;

export const Logo = styled.div`
  float: none;
  clear: both;
  display: inline-block;
  margin-left: 8%;
  vertical-align: middle;
  white-space: normal;
`;

export const LogoObject = styled.object`
  height: 28px;
  margin-top: 3px;
`;

export const LogoClickable = styled.div`
  float: none;
  clear: both;
  position: absolute;
  top: 21px;
  left: 0;
  width: 166px;
  height: 29px;
  margin-left: 10%;
  // background: ${({ snowClickable }) => (snowClickable ? "red" : "green")};

  pointer-events: ${({ snowClickable }) => (snowClickable ? "auto" : "none")};
  cursor: ${({ snowClickable }) => (snowClickable ? "pointer" : "default")};
`;
