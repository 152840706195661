import { createSlice } from "@reduxjs/toolkit";
import { partnerApiStart } from "../middleware/api";

const slice = createSlice({
  name: "UNI/Authenticator",
  initialState: {
    // Show main loader when true
    loading: false,
    loadingMessage: "",

    authorize: {
      wiggle: false,
      button: {
        loading: false,
      },
      error: {
        show: false,
        title: "",
        subtitle: "",
      },
    },

    // Identifies if a session token is present in local storage
    tokenized: false,

    // Global API error
    error: {
      isError: false,
      message: "",
      status: "",
      instance: "",
    },

    // User data
    user: {
      /*
       * c - compact
       * f - first
       */
      name: {},
    },
    /*
     * k - key (public uuid)
     * n - organization name
     * t - organization type
     * r - user role
     */
    entity: {},
  },
  reducers: {
    authorizeError: (authenticator, action) => {
      if (action.payload.title) {
        authenticator.authorize.error.title = action.payload.title;
        authenticator.authorize.error.show = true;

        if (action.payload.subtitle) {
          authenticator.authorize.error.subtitle = action.payload.subtitle;
        }
      } else {
        authenticator.authorize.error.show = false;
      }
    },

    authorizeErrorClear: (authenticator, action) => {
      authenticator.authorize.error.show = false;
      authenticator.authorize.error.title = "";
      authenticator.authorize.error.subtitle = "";
    },

    wiggleBox: (authenticator, action) => {
      authenticator.authorize.wiggle = action.payload;
    },

    buttonLoader: (authenticator, action) => {
      authenticator.authorize.button.loading = action.payload;
    },

    globalError: (authenticator, action) => {
      authenticator.error.isError = true;

      if (action.payload.message)
        authenticator.error.message = action.payload.message;
    },

    authorizeServerError: (authenticator, action) => {
      console.log("authorizeServerError", { action });

      // Disable the loader
      authenticator.authorize.button.loading = false;

      if (action.status === 403) {
        authenticator.authorize.error.title =
          "Грешна e-mail адреса или лозинка!";
        authenticator.authorize.error.subtitle = "Референца " + action.data.e.r;
        authenticator.authorize.error.show = true;

        authenticator.authorize.wiggle = true;
      }
    },

    /*
     * Checks if JWT token is in local storage
     * Sets tokenized to TRUE if it is
     */
    checkSession: (authenticator, action) => {
      // Check local storage for session token
      if (global.config.storage.session in localStorage) {
        // Token found in local storage
        authenticator.tokenized = true;

        // Show the loader while contacting the server
        authenticator.loading = true;
      } else {
        // Token found in local storage
        authenticator.tokenized = false;

        // Hide the loader while contacting the server
        authenticator.loading = false;
      }
    },

    clearSessionStart: (authenticator, action) => {
      authenticator.loading = true;
      authenticator.loadingMessage = "Одјавување...";
    },

    /*
     * Removes the token from local storage
     * Sets tokenized to FALSE in order to return the user to the login page
     * Add additioinal cleanup here; later on...
     */
    clearSession: (authenticator, action) => {
      authenticator.loading = false;
      authenticator.tokenized = false;
      localStorage.removeItem(global.config.storage.session);
      localStorage.removeItem(global.config.storage.info);
    },

    /*
     * Verifies the expected reponse from the server
     * Expected status is 204 - No Content
     */
    verifyHello: (authenticator, action) => {
      // Validate HTTP is OK
      if (action.status !== 200) {
        authenticator.tokenized = false;
      }
      // Store the user name data
      authenticator.user.name = action.data.u.n;
      // Store the entity data
      authenticator.entity = action.data.e;

      // Hide the loader
      authenticator.loading = false;
    },

    /*
     * Executed onError
     */
    verifyHelloError: (authenticator, action) => {
      // Hide loader
      authenticator.loading = false;
    },

    resetToken: (authenticator, action) => {
      // Show button loader
      authenticator.authorize.button.loading = true;

      // Make as not tokenized
      authenticator.tokenized = false;

      // Reset the wiggle
      authenticator.authorize.wiggle = false;

      // Reset errors
      authenticator.authorize.error.show = false;
      authenticator.authorize.error.title = "";
      authenticator.authorize.error.subtitle = "";
    },

    setSession: (authenticator, action) => {
      // Disable the loader
      authenticator.authorize.button.loading = false;

      // Validate HTTP is Created
      if (action.status !== 200) {
        authenticator.tokenized = false;
      }
      // Set token in local storage
      localStorage.setItem(global.config.storage.session, action.data.t);
      // Store the user name data
      authenticator.user.name = action.data.u.n;
      // Store the entity data
      authenticator.entity = action.data.e;
      authenticator.tokenized = true;
    },

    updateUser: (authenticator, action) => {
      const payload = JSON.stringify(action.data);
      if (
        JSON.parse(localStorage.getItem(global.config.storage.info)) !== payload
      ) {
        localStorage.setItem(global.config.storage.info, payload);
      }
    },
  },
});

export const {
  clearSession,
  globalError,
  authorizeError,
  authorizeErrorClear,
  wiggleBox,
  buttonLoader,
} = slice.actions;

export default slice.reducer;

// UPS - UNI PARTNER SESSION
// Validate JWT timestamp?? Possibly?

export const hello = () =>
  partnerApiStart({
    url: "/user/hello",
    method: "get",
    timeout: 10000,
    authenthicateRequired: true,
    onStart: slice.actions.checkSession.type,
    onSuccess: slice.actions.verifyHello.type,
    onError: slice.actions.verifyHelloError.type,
    expectedError: [401, 403],
  });

export const authorize = (data) =>
  partnerApiStart({
    url: "/user/authorize",
    method: "post",
    onStart: slice.actions.resetToken.type,
    onSuccess: slice.actions.setSession.type,
    onError: slice.actions.authorizeServerError.type,
    authorize: { email: data.email, password: data.password },
    captcha: data.captcha,
    expectedError: [403],
  });

export const revoke = () =>
  partnerApiStart({
    url: "/user/session",
    method: "delete",
    authenthicateRequired: true,
    onStart: slice.actions.clearSessionStart.type,
    onSuccess: slice.actions.clearSession.type,
  });
