import styled from "styled-components";

export const Container = styled.div`
  @media (max-width: 760px) {
    position: absolute;
  }

  position: fixed;
  float: none;
  clear: both;
  top: 0;
  left: 0;
  width: ${({ theme }) => theme.sidebar.width.percent}%;
  width: -webkit-calc(${({ theme }) => theme.sidebar.width.pixel}px);
  width: -moz-calc(${({ theme }) => theme.sidebar.width.pixel}px);
  width: calc(${({ theme }) => theme.sidebar.width.pixel}px);
  height: 100%;
  background: ${({ theme }) => theme.sidebar.background};
  z-index: 300;
`;

export const FilterListContainer = styled.div`
  -webkit-transition: opacity 0.3s, max-height 0.3s;
  -moz-transition: opacity 0.3s, max-height 0.3s;
  -o-transition: opacity 0.3s, max-height 0.3s;
  transition: opacity 0.3s, max-height 0.3s;
  transition-timing-function: ease-in-out;

  position: relative;
  float: left;
  width: 100%;
  z-index: 302;
  margin-top: 20px;
  height: 100%;

  max-height: ${({ containerHeight }) =>
    containerHeight ? String(containerHeight) + "px" : "0px"};

  opacity: ${({ containerHeight }) => (containerHeight ? "1" : "0")};

  display: block;

  background: #001735;

  &:before {
    z-index: 305;
    //content: "";
    width: 100%;
    height: 30px;
    position: absolute;
    top: -18px;
    left: 0;

    pointer-events: none;

    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#002d69+0,001735+50,001735+100&1+65,0+100 */
    background: -moz-linear-gradient(
      top,
      rgba(0, 45, 105, 1) 0%,
      rgba(0, 23, 53, 1) 50%,
      rgba(0, 23, 53, 1) 65%,
      rgba(0, 23, 53, 0) 100%
    ); /* FF3.6-15 */
    background: -webkit-linear-gradient(
      top,
      rgba(0, 45, 105, 1) 0%,
      rgba(0, 23, 53, 1) 50%,
      rgba(0, 23, 53, 1) 65%,
      rgba(0, 23, 53, 0) 100%
    ); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(
      to bottom,
      rgba(0, 45, 105, 1) 0%,
      rgba(0, 23, 53, 1) 50%,
      rgba(0, 23, 53, 1) 65%,
      rgba(0, 23, 53, 0) 100%
    ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#002d69', endColorstr='#00001735',GradientType=0 ); /* IE6-9 */

    //background-color: red;
  }

  &:after {
    z-index: 305;
    //content: "";
    width: 100%;
    height: 30px;
    position: absolute;
    bottom: -18px;
    left: 0;

    pointer-events: none;

    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#001735+0,001735+50,002d69+100&0+0,1+35 */
    background: -moz-linear-gradient(
      top,
      rgba(0, 23, 53, 0) 0%,
      rgba(0, 23, 53, 1) 35%,
      rgba(0, 23, 53, 1) 50%,
      rgba(0, 45, 105, 1) 100%
    ); /* FF3.6-15 */
    background: -webkit-linear-gradient(
      top,
      rgba(0, 23, 53, 0) 0%,
      rgba(0, 23, 53, 1) 35%,
      rgba(0, 23, 53, 1) 50%,
      rgba(0, 45, 105, 1) 100%
    ); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(
      to bottom,
      rgba(0, 23, 53, 0) 0%,
      rgba(0, 23, 53, 1) 35%,
      rgba(0, 23, 53, 1) 50%,
      rgba(0, 45, 105, 1) 100%
    ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00001735', endColorstr='#002d69',GradientType=0 ); /* IE6-9 */

    //background-color: red;
  }
`;

export const FilterListScroll = styled.div`
  width: 100%;

  position: relative;
  float: left;
  height: inherit;
  overflow-y: scroll;

  z-index: 300;

  ::-webkit-scrollbar-color: light;
  scrollbar-color: light;
`;

export const FilterList = styled.ul`
  width: 100%;
  //z-index: 302;
  //margin-top: 20px;

  //max-height: 62%;
  pointer-events: auto;
  //z-index: 280;

  //background: rgba(0, 0, 0, 0.2);
  background: #001735;
  //background: green;
`;

export const Pointer = styled.div`
  -webkit-transition: opacity 0.3s;
  -moz-transition: opacity 0.3s;
  -o-transition: opacity 0.3s;
  transition: opacity 0.3s;

  position: absolute;
  float: none;
  clear: both;

  left: 0;
  display: inline-block;
  margin-top: 80px;
  margin-left: 2%;
  vertical-align: middle;
  white-space: normal;

  opacity: ${({ show }) => (show ? "1" : "0")};

  pointer-events: none;
`;

export const PointerObject = styled.object`
  height: 450px;
  margin-top: 3px;

  pointer-events: none;
`;

export const FooterContainer = styled.div`
  position: absolute;
  float: none;
  clear: both;
  bottom: 0;
  left: 0;

  width: 90%;
  margin: 0 5% 20px;

  font-size: 9em;
  font-weight: 700;
  color: #ffffff;

  z-index: 300;
`;

export const FooterBG = styled.div`
  position: absolute;
  float: none;
  clear: both;
  bottom: 0;
  left: 0;

  width: 100%;
  height: 220px;
  margin: 0;

  pointer-events: none;

  z-index: 290;

  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#002d69+0,002d69+100&0+9,1+30 */
  background: -moz-linear-gradient(
    top,
    rgba(0, 45, 105, 0) 0%,
    rgba(0, 45, 105, 0) 9%,
    rgba(0, 45, 105, 1) 30%,
    rgba(0, 45, 105, 1) 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    top,
    rgba(0, 45, 105, 0) 0%,
    rgba(0, 45, 105, 0) 9%,
    rgba(0, 45, 105, 1) 30%,
    rgba(0, 45, 105, 1) 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to bottom,
    rgba(0, 45, 105, 0) 0%,
    rgba(0, 45, 105, 0) 9%,
    rgba(0, 45, 105, 1) 30%,
    rgba(0, 45, 105, 1) 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00002d69', endColorstr='#002d69',GradientType=0 ); /* IE6-9 */
`;

export const FooterDisclaimer = styled.div`
  width: 100%;
  margin-bottom: 10px;
  color: rgba(255, 255, 255, 0.5);

  font-weight: 500;
`;

export const FooterVersion = styled.div`
  width: 100%;
  margin-bottom: 10px;
  color: rgba(255, 255, 255, 0.5);

  font-weight: 500;
`;

export const FooterCopytight = styled.div`
  width: 100%;
  color: rgb(255, 255, 255);
  font-weight: 700;
`;

/*
  max-height: ${({ myself }) =>
    myself ? myself.filterListContainerRef.current.clientHeight + "px" : "60%"};
    */
/*
  max-height: ${({ container }) =>
    scrollHeight
      ? scrollHeight +
        "px"
      : "60%"};
  */
/*
 min-height: ${({ containerHeight }) =>
    containerHeight ? String(containerHeight / 2) + "px" : "0px"};

  max-height: ${({ scrollHeight }) =>
    scrollHeight ? scrollHeight + "px" : "60%"};

  _content: ${({ scrollHeight }) => console.log(scrollHeight)};

*/
