import { createGlobalStyle } from "styled-components";

import Roboto300_EOT from "./fonts/roboto/roboto-v20-cyrillic_latin-300.eot";
import Roboto300_EOT_IE_FIX from "./fonts/roboto/roboto-v20-cyrillic_latin-300.eot?#iefix";
import Roboto300_WOFF2 from "./fonts/roboto/roboto-v20-cyrillic_latin-300.woff2";
import Roboto300_WOFF from "./fonts/roboto/roboto-v20-cyrillic_latin-300.woff";
import Roboto300_TTF from "./fonts/roboto/roboto-v20-cyrillic_latin-300.ttf";
import Roboto300_SVG from "./fonts/roboto/roboto-v20-cyrillic_latin-300.svg?#Roboto";

import Roboto400_EOT from "./fonts/roboto/roboto-v20-cyrillic_latin-regular.eot";
import Roboto400_EOT_IE_FIX from "./fonts/roboto/roboto-v20-cyrillic_latin-regular.eot?#iefix";
import Roboto400_WOFF2 from "./fonts/roboto/roboto-v20-cyrillic_latin-regular.woff2";
import Roboto400_WOFF from "./fonts/roboto/roboto-v20-cyrillic_latin-regular.woff";
import Roboto400_TTF from "./fonts/roboto/roboto-v20-cyrillic_latin-regular.ttf";
import Roboto400_SVG from "./fonts/roboto/roboto-v20-cyrillic_latin-regular.svg?#Roboto";

import Roboto500_EOT from "./fonts/roboto/roboto-v20-cyrillic_latin-500.eot";
import Roboto500_EOT_IE_FIX from "./fonts/roboto/roboto-v20-cyrillic_latin-500.eot?#iefix";
import Roboto500_WOFF2 from "./fonts/roboto/roboto-v20-cyrillic_latin-500.woff2";
import Roboto500_WOFF from "./fonts/roboto/roboto-v20-cyrillic_latin-500.woff";
import Roboto500_TTF from "./fonts/roboto/roboto-v20-cyrillic_latin-500.ttf";
import Roboto500_SVG from "./fonts/roboto/roboto-v20-cyrillic_latin-500.svg?#Roboto";

import Roboto700_EOT from "./fonts/roboto/roboto-v20-cyrillic_latin-700.eot";
import Roboto700_EOT_IE_FIX from "./fonts/roboto/roboto-v20-cyrillic_latin-700.eot?#iefix";
import Roboto700_WOFF2 from "./fonts/roboto/roboto-v20-cyrillic_latin-700.woff2";
import Roboto700_WOFF from "./fonts/roboto/roboto-v20-cyrillic_latin-700.woff";
import Roboto700_TTF from "./fonts/roboto/roboto-v20-cyrillic_latin-700.ttf";
import Roboto700_SVG from "./fonts/roboto/roboto-v20-cyrillic_latin-700.svg?#Roboto";

import Roboto900_EOT from "./fonts/roboto/roboto-v20-cyrillic_latin-900.eot";
import Roboto900_EOT_IE_FIX from "./fonts/roboto/roboto-v20-cyrillic_latin-900.eot?#iefix";
import Roboto900_WOFF2 from "./fonts/roboto/roboto-v20-cyrillic_latin-900.woff2";
import Roboto900_WOFF from "./fonts/roboto/roboto-v20-cyrillic_latin-900.woff";
import Roboto900_TTF from "./fonts/roboto/roboto-v20-cyrillic_latin-900.ttf";
import Roboto900_SVG from "./fonts/roboto/roboto-v20-cyrillic_latin-900.svg?#Roboto";

import FontAwesome300_EOT from "./fonts/fa/fa-light-300.eot";
import FontAwesome300_EOT_IE_FIX from "./fonts/fa/fa-light-300.eot?#iefix";
import FontAwesome300_WOFF2 from "./fonts/fa/fa-light-300.woff2";
import FontAwesome300_WOFF from "./fonts/fa/fa-light-300.woff";
import FontAwesome300_TTF from "./fonts/fa/fa-light-300.ttf";
import FontAwesome300_SVG from "./fonts/fa/fa-light-300.svg?#fontawesome";

import FontAwesome400_EOT from "./fonts/fa/fa-regular-400.eot";
import FontAwesome400_EOT_IE_FIX from "./fonts/fa/fa-regular-400.eot?#iefix";
import FontAwesome400_WOFF2 from "./fonts/fa/fa-regular-400.woff2";
import FontAwesome400_WOFF from "./fonts/fa/fa-regular-400.woff";
import FontAwesome400_TTF from "./fonts/fa/fa-regular-400.ttf";
import FontAwesome400_SVG from "./fonts/fa/fa-regular-400.svg?#fontawesome";

import FontAwesome900_EOT from "./fonts/fa/fa-solid-900.eot";
import FontAwesome900_EOT_IE_FIX from "./fonts/fa/fa-solid-900.eot?#iefix";
import FontAwesome900_WOFF2 from "./fonts/fa/fa-solid-900.woff2";
import FontAwesome900_WOFF from "./fonts/fa/fa-solid-900.woff";
import FontAwesome900_TTF from "./fonts/fa/fa-solid-900.ttf";
import FontAwesome900_SVG from "./fonts/fa/fa-solid-900.svg?#fontawesome";

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 300;
    src: url(${Roboto300_EOT});
    src: local("Roboto Light"), local("Roboto-Light"),
      url(${Roboto300_EOT_IE_FIX}) format("embedded-opentype"),
      url(${Roboto300_WOFF2}) format("woff2"),
      url(${Roboto300_WOFF}) format("woff"),
      url(${Roboto300_TTF}) format("truetype"),
      url(${Roboto300_SVG}) format("svg");
  }
  @font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    src: url(${Roboto400_EOT});
    src: local("Roboto"), local("Roboto-Regular"),
      url(${Roboto400_EOT_IE_FIX}) format("embedded-opentype"),
      url(${Roboto400_WOFF2}) format("woff2"),
      url(${Roboto400_WOFF}) format("woff"),
      url(${Roboto400_TTF}) format("truetype"),
      url(${Roboto400_SVG}) format("svg");
  }
  @font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    src: url(${Roboto500_EOT});
    src: local("Roboto Medium"), local("Roboto-Medium"),
      url(${Roboto500_EOT_IE_FIX}) format("embedded-opentype"),
      url(${Roboto500_WOFF2}) format("woff2"),
      url(${Roboto500_WOFF}) format("woff"),
      url(${Roboto500_TTF}) format("truetype"),
      url(${Roboto500_SVG}) format("svg");
  }
  @font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 700;
    src: url(${Roboto700_EOT});
    src: local("Roboto Bold"), local("Roboto-Bold"),
      url(${Roboto700_EOT_IE_FIX}) format("embedded-opentype"),
      url(${Roboto700_WOFF2}) format("woff2"),
      url(${Roboto700_WOFF}) format("woff"),
      url(${Roboto700_TTF}) format("truetype"),
      url(${Roboto700_SVG}) format("svg");
  }
  @font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 900;
    src: url(${Roboto900_EOT});
    src: local("Roboto Black"), local("Roboto-Black"),
      url(${Roboto900_EOT_IE_FIX}) format("embedded-opentype"),
      url(${Roboto900_WOFF2}) format("woff2"),
      url(${Roboto900_WOFF}) format("woff"),
      url(${Roboto900_TTF}) format("truetype"),
      url(${Roboto900_SVG}) format("svg");
  }

  @font-face {
    font-family: "Font Awesome 5 Pro";
    font-style: normal;
    font-weight: 300;
    font-display: block;
    src: url(${FontAwesome300_EOT});
    src: url(${FontAwesome300_EOT_IE_FIX}) format("embedded-opentype"),
      url(${FontAwesome300_WOFF2}) format("woff2"),
      url(${FontAwesome300_WOFF}) format("woff"),
      url(${FontAwesome300_TTF}) format("truetype"),
      url(${FontAwesome300_SVG}) format("svg");
  }
  @font-face {
    font-family: "Font Awesome 5 Pro";
    font-style: normal;
    font-weight: 400;
    font-display: block;
    src: url(${FontAwesome400_EOT});
    src: url(${FontAwesome400_EOT_IE_FIX}) format("embedded-opentype"),
      url(${FontAwesome400_WOFF2}) format("woff2"),
      url(${FontAwesome400_WOFF}) format("woff"),
      url(${FontAwesome400_TTF}) format("truetype"),
      url(${FontAwesome400_SVG}) format("svg");
  }
  @font-face {
    font-family: "Font Awesome 5 Pro";
    font-style: normal;
    font-weight: 900;
    font-display: block;
    src: url(${FontAwesome900_EOT});
    src: url(${FontAwesome900_EOT_IE_FIX}) format("embedded-opentype"),
      url(${FontAwesome900_WOFF2}) format("woff2"),
      url(${FontAwesome900_WOFF}) format("woff"),
      url(${FontAwesome900_TTF}) format("truetype"),
      url(${FontAwesome900_SVG}) format("svg");
  }

  * {
    -webkit-writing-mode: horizontal-tb !important;
    writing-mode: horizontal-tb !important;
    text-rendering: auto;
    letter-spacing: normal;
    word-spacing: normal;
    text-transform: none;
    text-indent: 0;
    text-shadow: none;
    font-variant: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-text-stroke: 0.25px transparent;
    font-family: "${(props) => props.theme.font.default}", sans-serif;
    font-weight: 400;
    //font-size: 15px;
    color: ${({ theme }) => theme.page.text.global};
  }
  ::selection {
    background: ${({ theme }) => theme.page.selection.background};
    color: ${({ theme }) => theme.page.selection.text};
  }
    

  html body {
    -webkit-touch-callout: none; -webkit-user-select: none; -khtml-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none;
    //pointer-events: none;
    font-style: normal;
    background: ${(props) => props.theme.page.background};
    font-size: 10%;
    line-height: 1;

    //min-width: 760px;
    //overflow-x: auto;
  }
  
  span,
  form,
  ul,
  li,
  a,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  label {
    position: relative;
    float: left;
    clear: none;
    display: block;
    width: auto;
    height: auto;
    margin: 0;
    padding: 0;
    -webkit-touch-callout: none; -webkit-user-select: none; -khtml-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none;
    pointer-events: none;
    font-size: inherit;
  }
/*  span.i {
    font-family: "Font Awesome 5 Pro";
    font-weight: 900;
    display: inline-block;
  }*/

  h1 {
    font-size: 16em;
  }
  h2 {
    font-size: 15em;
  }
  h3 {
    font-size: 14em;
  }
  h4 {
    font-size: 13em;
  }
  h5 {
    font-size: 12em;
  }
  h6 {
    font-size: 11em;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 700;
  }
  
  ul,
  li {
    list-style: none;
  }
  
  a {
    text-decoration: none;
      -webkit-touch-callout: default; -webkit-user-select: auto; -khtml-user-select: auto; -moz-user-select: auto; -ms-user-select: auto; user-select: auto;
      pointer-events: auto;
  }
  
  input,
  button {
    position: relative; float: left; clear: none; display: block;
    width: auto; height: auto;
    margin: 0; padding: 0;
    font-size: 15px; line-height: 1;
    border: none; outline: none;
    box-shadow: none;
    pointer-events: auto;
    background: transparent;
  }
  
  input {
    -webkit-touch-callout: default; -webkit-user-select: auto; -khtml-user-select: auto; -moz-user-select: auto; -ms-user-select: auto; user-select: auto;
    text-align: left;
    -moz-border-radius: 100px; -webkit-border-radius: 100px; -khtml-border-radius: 100px; border-radius: 100px;
  }
  input::-webkit-input-placeholder { /* WebKit, Blink, Edge */
    color: ${({ theme }) => theme.sidebar.search.box.placeholder};
  }
  input:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
    color: ${({ theme }) => theme.sidebar.search.box.placeholder};
    opacity:  1;
  }
  input::-moz-placeholder { /* Mozilla Firefox 19+ */
    color: ${({ theme }) => theme.sidebar.search.box.placeholder};
    opacity:  1;
  }
  input:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: ${({ theme }) => theme.sidebar.search.box.placeholder};
  }
  input::-ms-input-placeholder { /* Microsoft Edge */
    color: ${({ theme }) => theme.sidebar.search.box.placeholder};
  }
  input:placeholder { /* Most modern browsers support this now. */
    color: ${({ theme }) => theme.sidebar.search.box.placeholder} ;
  }
  input:disabled {
    cursor: default;
  }
  
  button {
    cursor: pointer;
    -moz-border-radius: 10px; -webkit-border-radius: 10px; -khtml-border-radius: 10px; border-radius: 10px;
  }
  button:disabled {
    cursor: default;
  }
  
  html body div#uni {
    @media (max-width: 760px) {
      position: absolute;
    }

    position: fixed;
    float: none;
    clear: both;
    top: 0;
    left: 0;
    
    height: 100%;
    min-height: 800px;
    
    width: 100%;
    min-width: 760px;
    overflow-x: auto;
  }

  @keyframes wiggle {
    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }
    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }
    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }
    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }

  @-webkit-keyframes rotate {
    100% {
      -moz-transform: rotate(360deg);
      -webkit-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @-moz-keyframes rotate {
    100% {
      -moz-transform: rotate(360deg);
      -webkit-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @-ms-keyframes rotate {
    100% {
      -moz-transform: rotate(360deg);
      -webkit-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @-o-keyframes rotate {
    100% {
      -moz-transform: rotate(360deg);
      -webkit-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes rotate {
    100% {
      -moz-transform: rotate(360deg);
      -webkit-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  @-webkit-keyframes spinner {
    0% {
      stroke-dasharray: 1, 150;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -35;
    }
    100% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -124;
    }
  }
  @-moz-keyframes spinner {
    0% {
      stroke-dasharray: 1, 150;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -35;
    }
    100% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -124;
    }
  }
  @-ms-keyframes spinner {
    0% {
      stroke-dasharray: 1, 150;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -35;
    }
    100% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -124;
    }
  }
  @-o-keyframes spinner {
    0% {
      stroke-dasharray: 1, 150;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -35;
    }
    100% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -124;
    }
  }
  @keyframes spinner {
    0% {
      stroke-dasharray: 1, 150;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -35;
    }
    100% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -124;
    }
  }


  @-moz-keyframes jelly {
  from {
    transform: scale(1, 1);
  }
  30% {
    transform: scale(1.25, 0.75);
  }
  40% {
    transform: scale(0.75, 1.25);
  }
  50% {
    transform: scale(1.15, 0.85);
  }
  65% {
    transform: scale(0.95, 1.05);
  }
  75% {
    transform: scale(1.05, 0.95);
  }
  to {
    transform: scale(1, 1);
  }
}
@-webkit-keyframes jelly {
  from {
    transform: scale(1, 1);
  }
  30% {
    transform: scale(1.25, 0.75);
  }
  40% {
    transform: scale(0.75, 1.25);
  }
  50% {
    transform: scale(1.15, 0.85);
  }
  65% {
    transform: scale(0.95, 1.05);
  }
  75% {
    transform: scale(1.05, 0.95);
  }
  to {
    transform: scale(1, 1);
  }
}
@-o-keyframes jelly {
  from {
    transform: scale(1, 1);
  }
  30% {
    transform: scale(1.25, 0.75);
  }
  40% {
    transform: scale(0.75, 1.25);
  }
  50% {
    transform: scale(1.15, 0.85);
  }
  65% {
    transform: scale(0.95, 1.05);
  }
  75% {
    transform: scale(1.05, 0.95);
  }
  to {
    transform: scale(1, 1);
  }
}
@keyframes jelly {
  from {
    transform: scale(1, 1);
  }
  30% {
    transform: scale(1.25, 0.75);
  }
  40% {
    transform: scale(0.75, 1.25);
  }
  50% {
    transform: scale(1.15, 0.85);
  }
  65% {
    transform: scale(0.95, 1.05);
  }
  75% {
    transform: scale(1.05, 0.95);
  }
  to {
    transform: scale(1, 1);
  }
}
`;

export default GlobalStyle;
