import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { Container } from "./SearchStyle";

import SearchBox from "./SearchBox/SearchBox";
import Suggestions from "./Suggestions/Suggestions";

import {
  searchWiggle,
  suggestionClicked,
  suggestionShow,
  suggestionHide,
  showPointer,
} from "../../../redux/store/search";
import { getResult } from "../../../redux/store/body";

class Search extends React.Component {
  handleKeyDown = (event) => {
    if (event.key === "Enter") {
      if (
        this.props.searchSuggestionsList &&
        this.props.searchSuggestionsList.m.length === 1
      ) {
        this.props.dispatch(
          getResult({
            key: this.props.searchSuggestionsList.m[0].k,
          })
        );
        this.props.dispatch(suggestionHide());
        this.props.dispatch(showPointer(false));
      } else {
        this.props.dispatch(searchWiggle(false));
        setTimeout(() => this.props.dispatch(searchWiggle(true)), 1);
      }
    }
  };

  handleSuggestionShow = (event) => {
    //console.log(event);
    //console.log("searchSuggestionsShow", this.props.searchSuggestionsShow);
    if (this.props.searchSuggestionsList && !this.props.searchSuggestionsShow) {
      this.props.dispatch(suggestionShow());
    }
    //return event.target.focus();
    // console.log("handleSuggestionShow", event);
  };

  handleSuggestionHide = (event) => {
    if (this.props.searchSuggestionsList && this.props.searchSuggestionsShow) {
      this.props.dispatch(suggestionHide());
      return event.target.blur();
    }
  };

  handleClick = (event) => {
    console.log("handleClick", event);
    //const size = event.s.replace(/\D/g, "");
    //this.props.history.push("/search/" + size);
    this.props.dispatch(suggestionClicked(event));
    this.props.dispatch(
      getResult({
        key: event.k,
      })
    );
    //if (this.props.bodySearchFilterStock.name)
  };

  getSuggestions = () => {
    if (
      // Show the suggestions box
      this.props.searchSuggestionsShow &&
      // When search is NOT loading
      !this.props.searchLoading &&
      // If the list is not empty
      this.props.searchSuggestionsList
    ) {
      return (
        <Suggestions
          suggestions={this.props.searchSuggestionsList}
          handleClick={this.handleClick}
          handleSuggestionShow={this.handleSuggestionShow}
          handleSuggestionHide={this.handleSuggestionHide}
        />
      );
    }
  };

  render() {
    return (
      <Container>
        <SearchBox
          handleKeyDown={this.handleKeyDown}
          handleSuggestionShow={this.handleSuggestionShow}
          handleSuggestionHide={this.handleSuggestionHide}
        />
        {this.getSuggestions()}
      </Container>
    );
  }
}

export default withRouter(
  connect((state) => ({
    searchSuggestionsList: state.search.suggestions.list,
    searchSuggestionsShow: state.search.suggestions.show,
    searchLoading: state.search.loading,
    //bodySearchFilterStock: state.body.filter.stock.list,
  }))(Search)
);
