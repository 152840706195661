import React from "react";
import { connect } from "react-redux";
import { Route, Switch } from "react-router-dom";
import { hello } from "../redux/store/authenticator";

import {
  ErrorContainer,
  ErrorIcon,
  ErrorMessage,
  LoaderContainer,
  LoaderSVG,
  LoaderCircle,
  LoaderMessage,
} from "./PortalStyle/PortalStyle";

import Alerts from "./Alerts/Alerts";
import Authorize from "./Authorize/Authorize";
import Sidebar from "./Sidebar/Sidebar";
import MainContainer from "./MainContainer/MainContainer";
import Header from "./Header/Header";

class Portal extends React.Component {
  componentDidMount() {
    this.props.dispatch(hello());
  }

  /*
  isAuthenticated2 = () => {
    if (this.props.loading) {
      console.log("portal is loading");
      return (
        <LoaderContainer>
          <LoaderSVG viewBox="0 0 60 60">
            <LoaderCircle
              cx="30"
              cy="30"
              r="20"
              fill="none"
              strokeWidth="8"
            ></LoaderCircle>
          </LoaderSVG>
        </LoaderContainer>
      );
    } else {
      if (this.props.checked) {
        console.log("portal checked");
        if (this.props.tokenized) {
          console.log("portal tokenized");
          return <React.Fragment></React.Fragment>;
        } else {
          return <Authorize />;
        }
      } else {
        return (
          <TimeoutContainer>
            <TimeoutIcon />
            <TimeoutText>
              Неможе да се оствари конекција со серверот.
            </TimeoutText>
          </TimeoutContainer>
        );
      }
    }
  };
  */

  isAuthenticated = () => {
    /*
     * Server error
     */
    if (this.props.error.isError) {
      return (
        <ErrorContainer>
          <ErrorIcon />
          <ErrorMessage>{this.props.error.message}</ErrorMessage>
        </ErrorContainer>
      );
    }

    /*
     * Shows the main loader
     */
    if (this.props.loading) {
      return (
        <React.Fragment>
          <LoaderContainer>
            <LoaderSVG viewBox="0 0 60 60">
              <LoaderCircle
                cx="30"
                cy="30"
                r="20"
                fill="none"
                strokeWidth="8"
              ></LoaderCircle>
            </LoaderSVG>
          </LoaderContainer>
          {this.props.loadingMessage.length > 0 ? (
            <LoaderMessage>{this.props.loadingMessage}</LoaderMessage>
          ) : null}
        </React.Fragment>
      );
    } else {
      /*
       * If token is valid - is tokenized
       */
      if (this.props.tokenized) {
        return (
          <React.Fragment>
            <Switch>
              <Route path="/">
                <Sidebar />
                <Header />
                <MainContainer />
              </Route>
            </Switch>
          </React.Fragment>
        );
      } else {
        /*
         * Otherwise return the login page
         */
        return <Authorize />;
      }
    }
  };

  render() {
    return (
      <React.Fragment>
        <Alerts />
        {this.isAuthenticated()}
      </React.Fragment>
    );
  }
}

//export default Portal;

/*
// <Route path="/about" component={About} />
               // <Route path="/shop" component={Shop} />
                <Route component={Error} />
 */
export default connect(
  ({ authenticator }) => ({
    tokenized: authenticator.tokenized,
    loading: authenticator.loading,
    loadingMessage: authenticator.loadingMessage,
    error: authenticator.error,
  }),
  null
)(Portal);
