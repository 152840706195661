import styled from "styled-components";

export const Form = styled.form`
  width: 100%;
`;

export const List = styled.ul`
  width: 100%;
`;

export const ItemInput = styled.li`
  width: 90%;
  margin: 0 5%;
  overflow: hidden;
  //background: red;
  -moz-border-radius: 100px;
  -webkit-border-radius: 100px;
  -khtml-border-radius: 100px;
  border-radius: 100px;
  z-index: 320;

  //&:focus-within {}
`;

export const Input = styled.input`
  width: 72%;
  height: 100%;
  padding: 12px 14% 12px 14%;
  font-weight: 900;
  font-size: 17em;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  -khtml-border-radius: 0;
  border-radius: 0;
  color: ${({ theme }) => theme.sidebar.search.box.text};
  background: ${({ theme }) => theme.sidebar.search.box.background};

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  &[type="number"] {
    -moz-appearance: textfield;
  }
`;

export const ItemIcon = styled.li`
  position: absolute;
  float: none;
  clear: both;
  top: 50%;
  width: 32px;
  height: 48px;
  margin-top: -24px;
  left: ${({ right }) => (right ? "unset" : "7%")};
  right: ${({ right }) => (right ? "7%" : "unset")};
  //background: green;
  //opacity: 0.5;
  z-index: 321;
  text-align: center;
`;

export const IconSearch = styled.span`
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
  // ^cross browser compatibility
  width: 100%;
  height: 100%;
  display: inline-block;
  color: ${({ theme }) => theme.sidebar.search.box.icon};
  font-size: 26px;
  line-height: 48px;
  font-family: "${({ theme }) => theme.font.icon}";
  font-weight: 700;
  &::before {
    content: "\f002";
  }
  &.a {
    -webkit-animation: wiggle 0.85s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
    -moz-animation: wiggle 0.85s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
    -o-animation: wiggle 0.85s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
    animation: wiggle 0.85s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  }
`;

export const LoaderSVG = styled.svg`
  -webkit-animation: rotate 2s linear infinite;
  -moz-animation: rotate 2s linear infinite;
  -ms-animation: rotate 2s linear infinite;
  -o-animation: rotate 2s linear infinite;
  animation: rotate 2s linear infinite;

  width: 100%;
  height: 100%;
  //background: rgba(23, 200, 44, 0.4);
`;

export const LoaderCircle = styled.circle`
  -webkit-animation: spinner 1.5s ease-in-out infinite;
  -moz-animation: spinner 1.5s ease-in-out infinite;
  -ms-animation: spinner 1.5s ease-in-out infinite;
  -o-animation: spinner 1.5s ease-in-out infinite;
  animation: spinner 1.5s ease-in-out infinite;

  stroke: ${({ theme }) => theme.sidebar.search.box.loader};
  stroke-linecap: round;
  stroke-dasharray: 90, 150;
  stroke-dashoffset: 0;
`;
