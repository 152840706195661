import React from "react";
import { connect } from "react-redux";

import {
  Form,
  List,
  ItemInput,
  Input,
  ItemIcon,
  IconSearch,
  LoaderSVG,
  LoaderCircle,
} from "./SearchBoxStyle";

import { handleSearch, searchEmpty } from "../../../../redux/store/search";

class SearchBox extends React.Component {
  handleChange = (box) => {
    const value = box.target.value.replace(/\D/g, "");

    if (value.length === 0) {
      this.props.dispatch(searchEmpty());
    } else {
      this.props.dispatch(handleSearch(value));
    }
  };

  handleSubmit = (form) => {
    form.preventDefault();
  };

  handleInputOnMouseEnter = (e) => {
    // Check if element is focused a.k.a. active
    // If it is not, focus it
    if (document.activeElement !== e.target) {
      e.target.focus();
      e.target.setSelectionRange(e.target.value.length, e.target.value.length);
    }
    // Show the suggestions when focused
    if (e.target.value.length > 0) {
      this.props.handleSuggestionShow();
    }
  };

  handleInputOnMouseLeave = (e) => {
    // Check if element is focused a.k.a. active
    // If it is, blur it
    if (document.activeElement === e.target) {
      e.target.blur();
    }
  };

  handleInputOnClick = (e) => {
    // On click, select the value
    e.target.select();
  };

  getLoader = () => {
    if (this.props.loading) {
      return (
        <ItemIcon right={true}>
          <LoaderSVG viewBox="0 0 60 60">
            <LoaderCircle
              cx="30"
              cy="30"
              r="20"
              fill="none"
              strokeWidth="8"
            ></LoaderCircle>
          </LoaderSVG>
        </ItemIcon>
      );
    }
  };

  render() {
    return (
      <Form autoComplete="off" onSubmit={this.handleSubmit}>
        <List>
          <ItemIcon>
            <IconSearch className={this.props.wiggle ? "a" : ""}></IconSearch>
          </ItemIcon>
          {this.getLoader()}
          <ItemInput>
            <Input
              type="text"
              autoComplete="off"
              value={this.props.query}
              onChange={this.handleChange}
              placeholder="..."
              onKeyDown={this.props.handleKeyDown}
              onFocus={this.props.handleSuggestionShow}
              onMouseEnter={this.handleInputOnMouseEnter}
              onMouseLeave={this.handleInputOnMouseLeave}
              onClick={this.handleInputOnClick}
            />
          </ItemInput>
        </List>
      </Form>
    );
  }
}

export default connect(
  ({ search }) => ({
    query: search.query,
    loading: search.loading,
    suggestions_show: search.suggestions.show,
    wiggle: search.wiggle,
  }),
  null
)(SearchBox);
