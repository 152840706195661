import styled from "styled-components";

export const Container = styled.div`
  @media (max-width: 760px) {
    position: absolute;
  }

  margin-top: ${({ theme }) => theme.header.height.percent}%;
  margin-top: -webkit-calc(${({ theme }) => theme.header.height.pixel}px);
  margin-top: -moz-calc(${({ theme }) => theme.header.height.pixel}px);
  margin-top: calc(${({ theme }) => theme.header.height.pixel}px);

  height: ${({ theme }) => Math.abs(100 - theme.header.height.percent)}%;
  height: -webkit-calc(100% - ${({ theme }) => theme.header.height.pixel}px);
  height: -moz-calc(100% - ${({ theme }) => theme.header.height.pixel}px);
  height: calc(100% - ${({ theme }) => theme.header.height.pixel}px);

  position: fixed;
  float: none;
  clear: both;
  top: 0;
  right: 0;

  width: ${({ theme }) => 100 - theme.sidebar.width.percent}%;
  width: -webkit-calc(100% - ${({ theme }) => theme.sidebar.width.pixel}px);
  width: -moz-calc(100% - ${({ theme }) => theme.sidebar.width.pixel}px);
  width: calc(100% - ${({ theme }) => theme.sidebar.width.pixel}px);

  //min-width: 400px;

  z-index: 2;
  pointer-events: auto;
  overflow: auto;

  //background: red;
`;

export const ShadowTop = styled.div`
  -webkit-transition: opacity 0.25s;
  -moz-transition: opacity 0.25s;
  -o-transition: opacity 0.25s;
  transition: opacity 0.25s;

  @media (max-width: 760px) {
    position: absolute;
    position: sitcky;
  }
  position: fixed;

  pointer-events: none;
  top: 0;
  right: 0;

  margin-top: ${({ theme }) => theme.header.height.percent}%;
  margin-top: -webkit-calc(${({ theme }) => theme.header.height.pixel}px);
  margin-top: -moz-calc(${({ theme }) => theme.header.height.pixel}px);
  margin-top: calc(${({ theme }) => theme.header.height.pixel}px);

  /*
  width: ${({ theme }) => 100 - theme.sidebar.width.percent}%;
  width: -webkit-calc(100% - ${({ theme }) => theme.sidebar.width.pixel}px);
  width: -moz-calc(100% - ${({ theme }) => theme.sidebar.width.pixel}px);
  width: calc(100% - ${({ theme }) => theme.sidebar.width.pixel}px);
  */

  width: 100%;

  height: ${({ theme }) => theme.header.height.percent}%;
  height: -webkit-calc(${({ theme }) => theme.header.height.pixel}px);
  height: -moz-calc(${({ theme }) => theme.header.height.pixel}px);
  height: calc(${({ theme }) => theme.header.height.pixel}px);

  opacity: ${({ show }) => (show ? 1 : 0)};

  z-index: 151;

  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#000d1e+0,000d1e+100&0.07+0,0+100 */
  background: -moz-linear-gradient(
    top,
    rgba(0, 13, 30, 0.08) 0%,
    rgba(0, 13, 30, 0) 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    top,
    rgba(0, 13, 30, 0.08) 0%,
    rgba(0, 13, 30, 0) 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to bottom,
    rgba(0, 13, 30, 0.08) 0%,
    rgba(0, 13, 30, 0) 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#12000d1e', endColorstr='#00000d1e',GradientType=0 ); /* IE6-9 */

  //background: red;
`;

export const ShadowBottom = styled.div`
  -webkit-transition: opacity 0.25s;
  -moz-transition: opacity 0.25s;
  -o-transition: opacity 0.25s;
  transition: opacity 0.25s;

  @media (max-width: 760px) {
    position: absolute;
    position: sitcky;
  }
  position: fixed;

  pointer-events: none;
  bottom: 0;
  right: 0;

  /*
  width: ${({ theme }) => 100 - theme.sidebar.width.percent}%;
  width: -webkit-calc(100% - ${({ theme }) => theme.sidebar.width.pixel}px);
  width: -moz-calc(100% - ${({ theme }) => theme.sidebar.width.pixel}px);
  width: calc(100% - ${({ theme }) => theme.sidebar.width.pixel}px);
  */

  width: 100%;

  height: ${({ theme }) => theme.header.height.percent}%;
  height: -webkit-calc(${({ theme }) => theme.header.height.pixel}px);
  height: -moz-calc(${({ theme }) => theme.header.height.pixel}px);
  height: calc(${({ theme }) => theme.header.height.pixel}px);

  opacity: ${({ show }) => (show ? 1 : 0)};

  z-index: 151;

  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#000d1e+0,000d1e+100&0+0,0.07+100 */
  background: -moz-linear-gradient(
    top,
    rgba(0, 13, 30, 0) 0%,
    rgba(0, 13, 30, 0.1) 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    top,
    rgba(0, 13, 30, 0) 0%,
    rgba(0, 13, 30, 0.1) 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to bottom,
    rgba(0, 13, 30, 0) 0%,
    rgba(0, 13, 30, 0.1) 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000d1e', endColorstr='#12000d1e',GradientType=0 ); /* IE6-9 */

  //background: red;
`;
