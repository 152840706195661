import React from "react";

import {
  Item,
  ItemListLeft,
  ListLeftItemTitle,
  ListLeftItemBrand,
  ListLeftItemCode,
  ListLeftItemBarcode,
  PendantList,
  PendantItem,
  ItemListRight,
  ListRightStock,
  ListRightStockList,
  ListRightStockItem,
  ListRightStockItemText,
  ListRightPrice,
  ListRightIcon,
  SupplierStock,
  SupplierStockItem,
  SupplierStockLoader,
  LoaderSVG,
  LoaderCircle,
  TireLabelRatingList,
  TireLabelRatingFuel2020,
  TireLabelRatingFuel2009,
  TireLabelRatingGrip2020,
  TireLabelRatingGrip2009,
  TireLabelRatingNoise2020,
  TireLabelRatingNoise2009,
  TireLabelRatingText,
  TireLabelRatingFuelIcon,
  TireLabelRatingGripIcon,
  TireLabelRatingNoiseIcon2020,
  TireLabelRatingNoiseIcon2009,
} from "./SearchItemStyle";

const warehouseName = {
  pp: "Прилеп",
  sk: "Скопје",
};

class SearchItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expandStock: false,
    };
  }

  getCode = (itemData) => {
    if (itemData.k === itemData.d.c) {
      return <ListLeftItemCode>MID {itemData.d.c}</ListLeftItemCode>;
    } else {
      const uid = parseInt(itemData.k).toString();
      return (
        <ListLeftItemCode>
          {"UID " + uid + " (MID " + itemData.d.c + ")"}
        </ListLeftItemCode>
      );
    }
  };
  getBarcode = (itemData) => {
    if (itemData.e) {
      // Checking if var is object to comply with legacy local storage data
      // Remove at a later time
      if (typeof itemData.e === "object") {
        if (itemData.e.length > 1) {
          return (
            <ListLeftItemBarcode>
              EAN {itemData.e.join(", ")}
            </ListLeftItemBarcode>
          );
        } else {
          return <ListLeftItemBarcode>EAN {itemData.e[0]}</ListLeftItemBarcode>;
        }
      } else {
        return <ListLeftItemBarcode>EAN {itemData.e}</ListLeftItemBarcode>;
      }
    }
  };

  getAxel = (itemData) => {
    // When the Axel is set and if it's not A - All
    // Осовина
    if (itemData.a) {
      switch (itemData.a) {
        case "S":
          // Truck Tire Steer
          return <PendantItem>Предна</PendantItem>;
        case "D":
          // Truck Tire Drive
          return <PendantItem>Погонска</PendantItem>;
        case "T":
          // Truck Tire Trailer
          return <PendantItem>Приколка</PendantItem>;
        default:
          return null;
      }
    }
  };
  getLoadRange = (itemData) => {
    if (itemData.r && itemData.r !== "SL") {
      // Check if PLY is set
      if (itemData.y) {
        return (
          <PendantItem>
            {itemData.r} ({itemData.y}-ply)
          </PendantItem>
        );
      } else {
        switch (itemData.r) {
          case "XL":
            return <PendantItem>Extra Load</PendantItem>;
          case "LL":
            return <PendantItem>Light Load</PendantItem>;
          case "RF":
            return <PendantItem>Reinforced</PendantItem>;
          default:
            return null;
        }
      }
    }
  };
  getPosition = (itemData) => {
    if (itemData.p) {
      switch (itemData.p) {
        case "FL": // Front Left
          return <PendantItem>Предна лева</PendantItem>;
        case "FR": // Front Right
          return <PendantItem>Предна десна</PendantItem>;
        case "FX": // Front
          return <PendantItem>Предна</PendantItem>;
        case "ML": // Middle Left
          return <PendantItem>Средна лева</PendantItem>;
        case "MR": // Middle Right
          return <PendantItem>Средна десна</PendantItem>;
        case "MX": // Middle
          return <PendantItem>Средна</PendantItem>;
        case "RL": // Rear Left
          return <PendantItem>Задна лева</PendantItem>;
        case "RR": // Rear Right
          return <PendantItem>Задна десна</PendantItem>;
        case "RX": // Rear
          return <PendantItem>Задна</PendantItem>;
        case "XL": // Left
          return <PendantItem>Лева</PendantItem>;
        case "XR": // Right
          return <PendantItem>Десна</PendantItem>;
        default:
          return null;
      }
    }
  };
  getMS = (itemData) => {
    if (itemData.m) {
      return <PendantItem>M+S</PendantItem>;
    }
  };
  getTPMSF = (itemData) => {
    if (itemData.t) {
      return <PendantItem>3PMSF</PendantItem>;
    }
  };

  getRunFlat = (itemData) => {
    if (itemData.u.v) {
      return <PendantItem>{itemData.u.v}</PendantItem>;
    }
  };
  getFringeProtector = (itemData) => {
    if (itemData.f.v) {
      return <PendantItem>{itemData.f.v}</PendantItem>;
    }
  };
  getNoiseReducer = (itemData) => {
    if (itemData.n.v) {
      return <PendantItem>{itemData.n.v}</PendantItem>;
    }
  };
  getSidewall = (itemData) => {
    if (itemData.w) {
      switch (itemData.w) {
        case "BSW": // Black Sidewall
          return null;
        default:
          return <PendantItem>{itemData.w}</PendantItem>;
      }
    }
  };

  handleStockExceeds = (exceeds) => {
    if (exceeds) {
      return "+";
    }
    return null;
  };

  getStock = (itemData) => {
    // If stock is greater than 0
    if (itemData.q.v > 0) {
      // Detect if it is legacy or lastest stock warehouse-city breakdown
      if (itemData.q.b && (itemData.q.b.pp || itemData.q.b.sk)) {
        // Empry array of objects to store and sort the stock breakdown
        var stockInWarehouse = [];

        // Process the backend data
        for (const key in itemData.q.b) {
          if (itemData.q.b[key].v > 0) {
            stockInWarehouse.push({
              key,
              stock: itemData.q.b[key].v,
              exceeds: itemData.q.b[key].e,
            });
          }
        }

        // Sort by stock from biggest to smallest
        stockInWarehouse = stockInWarehouse.sort((a, b) => b.stock - a.stock);

        // If stock is available at only 1 warehouse
        if (stockInWarehouse.length === 1) {
          return (
            <ListRightStock>
              <ListRightStockList>
                <ListRightStockItem>
                  <ListRightStockItemText>
                    {stockInWarehouse[0].stock +
                      this.handleStockExceeds(stockInWarehouse[0].exceeds)}{" "}
                    во {warehouseName[stockInWarehouse[0].key]}
                  </ListRightStockItemText>
                </ListRightStockItem>
              </ListRightStockList>
            </ListRightStock>
          );
        } else {
          // Stock is available at more than 1 warehouse
          return (
            <ListRightStock>
              <ListRightStockList>
                {stockInWarehouse.map((item) => (
                  <ListRightStockItem key={item.key}>
                    <ListRightStockItemText>
                      {item.stock + this.handleStockExceeds(item.exceeds)} во{" "}
                      {warehouseName[item.key]}
                    </ListRightStockItemText>
                  </ListRightStockItem>
                ))}
              </ListRightStockList>
            </ListRightStock>
          );
        }
      } else {
        console.log("legacy stock", itemData.q);
        // Show the legacy stock -- remove at a later point
        return (
          <ListRightStock>
            <ListRightStockList>
              <ListRightStockItem>
                <ListRightStockItemText>
                  Достапни {itemData.q.v}
                </ListRightStockItemText>
              </ListRightStockItem>
            </ListRightStockList>
          </ListRightStock>
        );
      }
    }
  };
  getPrice = (itemData) => {
    if (itemData.c && !itemData.l) {
      return (
        <ListRightPrice>
          {itemData.c.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " MKD"}
        </ListRightPrice>
      );
    }
  };
  getSeason = (itemData) => {
    if (!itemData.l) {
      if (itemData.s) {
        switch (itemData.s) {
          case "A": // All Season
            return <ListRightIcon icon={"f764"} title="Сите сезони" />;
          case "S": // Summer
            return <ListRightIcon icon={"f185"} title="Летна" />;
          case "W": // Winter
            return <ListRightIcon icon={"f2dc"} title="Зимска" />;
          default:
            return null;
        }
      }
    }
  };

  getSupplierStock = (itemData) => {
    if (itemData.l) {
      return (
        <SupplierStockLoader>
          <LoaderSVG viewBox="0 0 60 60">
            <LoaderCircle
              cx="30"
              cy="30"
              r="20"
              fill="none"
              strokeWidth="8"
            ></LoaderCircle>
          </LoaderSVG>
        </SupplierStockLoader>
      );
    } else {
      if (itemData.fs.length > 0) {
        return (
          <SupplierStock>
            {itemData.fs.map((item) =>
              item.a > 1 ? (
                <SupplierStockItem key={itemData.d.c + item.a + item.d}>
                  {item.a} за {item.d}
                </SupplierStockItem>
              ) : (
                <SupplierStockItem key={itemData.d.c + item.a + item.d}>
                  {item.a} за {item.d}
                </SupplierStockItem>
              )
            )}
          </SupplierStock>
        );
      } else {
        if (itemData.q.length === 0 || (!itemData.l && itemData.q.v === 0)) {
          return (
            <SupplierStock>
              <SupplierStockItem>Недостапна</SupplierStockItem>
            </SupplierStock>
          );
        }
      }
    }
  };

  getRatingLabelEU = (itemData) => {
    if (itemData.el && itemData.el.d) {
      switch (itemData.el.d) {
        case "2020/740": // New rating
          return (
            <TireLabelRatingList>
              <TireLabelRatingFuel2020 rating={itemData.el.f}>
                <TireLabelRatingFuelIcon />
                <TireLabelRatingText>{itemData.el.f}</TireLabelRatingText>
              </TireLabelRatingFuel2020>
              <TireLabelRatingGrip2020 rating={itemData.el.g}>
                <TireLabelRatingGripIcon />
                <TireLabelRatingText>{itemData.el.g}</TireLabelRatingText>
              </TireLabelRatingGrip2020>
              <TireLabelRatingNoise2020 rating={itemData.el.c}>
                <TireLabelRatingNoiseIcon2020 rating={itemData.el.c} />
                <TireLabelRatingText>
                  {itemData.el.c} ({itemData.el.n} dB)
                </TireLabelRatingText>
              </TireLabelRatingNoise2020>
            </TireLabelRatingList>
          );
        case "1222/2009": // Old rating
          return (
            <TireLabelRatingList>
              <TireLabelRatingFuel2009 rating={itemData.el.f}>
                <TireLabelRatingFuelIcon />
                <TireLabelRatingText>{itemData.el.f}</TireLabelRatingText>
              </TireLabelRatingFuel2009>
              <TireLabelRatingGrip2009 rating={itemData.el.g}>
                <TireLabelRatingGripIcon />
                <TireLabelRatingText>{itemData.el.g}</TireLabelRatingText>
              </TireLabelRatingGrip2009>
              <TireLabelRatingNoise2009 rating={itemData.el.c}>
                <TireLabelRatingNoiseIcon2009 rating={itemData.el.c} />
                <TireLabelRatingText>{itemData.el.n} dB</TireLabelRatingText>
              </TireLabelRatingNoise2009>
            </TireLabelRatingList>
          );
        default:
          return null;
      }
    }
  };

  handleMouseEnter = (stock) => {
    if (stock.v > 0) {
      this.setState({ expandStock: true });
    }
  };
  handleMouseLeave = (stock) => {
    if (stock.v > 0) {
      this.setState({ expandStock: false });
    }
  };

  render() {
    const itemData = this.props.item;
    return (
      <Item>
        <ItemListLeft>
          <ListLeftItemTitle>{itemData.d.d}</ListLeftItemTitle>
          <ListLeftItemBrand>{itemData.b.f}</ListLeftItemBrand>
          <PendantList>
            {this.getAxel(itemData)}
            {this.getPosition(itemData)}
            {this.getMS(itemData)}
            {this.getTPMSF(itemData)}
            {this.getLoadRange(itemData)}
            {this.getRunFlat(itemData)}
            {this.getNoiseReducer(itemData)}
            {this.getFringeProtector(itemData)}
            {this.getSidewall(itemData)}
          </PendantList>
          {this.getRatingLabelEU(itemData)}
          {this.getBarcode(itemData)}
          {this.getCode(itemData)}
        </ItemListLeft>
        <ItemListRight>
          {this.getSeason(itemData)}
          {this.getStock(itemData)}
          {this.getSupplierStock(itemData)}
          {this.getPrice(itemData)}
        </ItemListRight>
      </Item>
    );
  }
}

export default SearchItem;
