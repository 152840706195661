import React from "react";

import { Container, ShadowTop } from "./MainContainerStyle";

import Body from "./Body/Body";

class MainContainer extends React.Component {
  constructor(props) {
    super(props);
    this.containerRef = React.createRef();
    this.containerObserver = null;
    this.state = {
      top: false,
    };
  }

  handleScroll = (e) => {
    const top = e.target.scrollTop;
    const position = e.target.scrollHeight - top;
    const minClientHeight = e.target.clientHeight - 40;

    if (top < 10) {
      this.setState({ top: false });
    } else {
      this.setState({ top: true });
    }

    if (
      top > 10 &&
      position >= minClientHeight &&
      position <= e.target.clientHeight
    ) {
      this.setState({ top: true });
    }
  };

  componentDidMount() {
    this.containerObserver = new MutationObserver(this.handleResize);
    this.containerObserver.observe(this.containerRef.current, {
      childList: true,
      subtree: true,
    });
  }

  componentWillUnmount() {
    if (this.containerObserver) {
      this.containerObserver.disconnect();
    }
  }

  handleResize = () => {
    const scrollHeight = this.containerRef.current.scrollHeight;
    const clientHeight = this.containerRef.current.clientHeight;

    if (scrollHeight === clientHeight) {
      this.setState({ top: false });
    } else {
    }
  };

  render() {
    return (
      <React.Fragment>
        <Container ref={this.containerRef} onScroll={this.handleScroll}>
          <Body />
        </Container>
        <ShadowTop show={this.state.top} />
      </React.Fragment>
    );
  }
}

export default MainContainer;
