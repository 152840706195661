import React, { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

import "./config";

import { Provider } from "react-redux";
import configureStore from "./redux/store/configureStore";

import lightTheme from "./theme/light";
//import darkTheme from "./theme/dark";
import GlobalStyle from "./theme/globalStyle";

import { StyleSheetManager, ThemeProvider } from "styled-components";
//import { useModeSelector } from "use-light-switch";

import Portal from "./Portal/Portal";
import reportWebVitals from "./reportWebVitals";

const store = configureStore;

setTimeout(
  console.log.bind(
    console,
    "%cUniroyal Partner v" + process.env.REACT_APP_VERSION,
    "color: #a3cb37; font-size: 20px; font-weight: bold; background-color: #002d69; padding: 5px 15px; border-radius: 5px;"
  ),
  0
);
setTimeout(
  console.log.bind(
    console,
    "%cCopyright ©" +
      new Date().getFullYear() +
      " UNIROYAL CO. DOOEL\nAll rights reserved.",
    "color: #002d69; font-size: 16px"
  ),
  0
);

const Partner = () => {
  /*
  const theme = useModeSelector({
    light: lightTheme,
    dark: lightTheme,
    unset: lightTheme,
  });
  */
  const theme = lightTheme;

  return (
    <Provider store={store}>
      <StyleSheetManager>
        <ThemeProvider theme={theme}>
          <BrowserRouter>
            <Portal />
          </BrowserRouter>
          <GlobalStyle />
        </ThemeProvider>
      </StyleSheetManager>
    </Provider>
  );
};

const root = createRoot(document.getElementById("uni"));

root.render(<Partner />);

/*
Strtick Mode rendering twice - turned off
root.render(
  <StrictMode>
    <Partner />
  </StrictMode>
);
*/

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
