import styled from "styled-components";

export const Container = styled.div`
  @media (max-width: 800px) {
    position: absolute;
    width: 80%;
    max-width: 100%;
    margin-left: -40%;
  }

  @media (max-height: 800px) {
    margin-top: -120px;
  }

  position: fixed;
  top: 50%;
  left: 50%;
  float: none;
  width: 60%;
  min-height: 340px;
  max-width: 600px;
  margin-top: -170px;
  margin-left: -30%;

  &.a {
    -webkit-animation: wiggle 0.85s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
    -moz-animation: wiggle 0.85s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
    -o-animation: wiggle 0.85s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
    animation: wiggle 0.85s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  }
`;

export const Form = styled.form`
  position: absolute;
  float: none;
  width: 100%;
  background: ${({ theme }) => theme.sidebar.background};

  -moz-border-radius: 20px;
  -webkit-border-radius: 20px;
  -khtml-border-radius: 20px;
  border-radius: 20px;

  z-index: 50;
`;

export const Logo = styled.div`
  float: none;
  clear: both;
  display: inline-block;
  vertical-align: middle;
  white-space: normal;
  margin: 40px 0 0 8%;
`;

export const LogoObject = styled.object`
  height: 28px;
  margin-top: 3px;
`;

export const List = styled.ul`
  width: 100%;
  margin: 40px 0 0;
`;

export const ItemInput = styled.li`
  -webkit-transition: box-shadow 0.3s;
  -moz-transition: box-shadow 0.3s;
  -o-transition: box-shadow 0.3s;
  transition: box-shadow 0.3s;

  width: 90%;
  margin: 0 5% 10px;
  overflow: hidden;

  background: ${({ theme }) => theme.authorize.input.background};

  -moz-border-radius: 100px;
  -webkit-border-radius: 100px;
  -khtml-border-radius: 100px;
  border-radius: 100px;
  z-index: 301;

  /*
  outline: 5px solid
    ${({ valid, theme }) =>
    valid ? "transparent" : theme.alert.background.error};
  outline-offset: -5px;
*/

  -webkit-box-shadow: inset 0px 0px 0px 5px
    ${({ valid, theme }) =>
      valid ? "transparent" : theme.alert.background.error};
  -moz-box-shadow: inset 0px 0px 0px 5px
    ${({ valid, theme }) =>
      valid ? "transparent" : theme.alert.background.error};
  box-shadow: inset 0px 0px 0px 5px
    ${({ valid, theme }) =>
      valid ? "transparent" : theme.alert.background.error};
`;

export const Input = styled.input`
  width: 88%;
  height: 100%;
  padding: 12px 6% 12px 6%;
  font-weight: 900;
  font-size: 14em;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  -khtml-border-radius: 0;
  border-radius: 0;
  color: ${({ theme }) => theme.authorize.input.text};

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    font-weight: 900;
    font-size: 14em;

    color: ${({ theme }) => theme.authorize.input.text};
    -webkit-text-fill-color: ${({ theme }) => theme.authorize.input.text};
    background: rgba(255, 255, 255, 0.001);
    background-color: rgba(255, 255, 255, 0.001);
    -webkit-box-shadow: 0 0 0px 1000px rgba(255, 255, 255, 1) inset;
  }

  filter: none;
`;

export const Button = styled.button`
  -webkit-transition: color 0.15s;
  -moz-transition: color 0.15s;
  -o-transition: color 0.15s;
  transition: color 0.15s;

  color: white;
  padding: 16px 6%;
  margin: 15px 6% 20px 6%;
  text-transform: uppercase;
  font-weight: 900;
  font-size: 10em;
  color: ${({ theme }) => theme.authorize.button.text};
  background: ${({ theme }) => theme.authorize.button.background.default};

  &:disabled {
    cursor: default;
    color: ${({ theme }) => theme.authorize.button.background.default};
  }
`;

export const ErrorContainer = styled.div`
  -webkit-transition: bottom 0.35s;
  -moz-transition: bottom 0.35s;
  -o-transition: bottom 0.35s;
  transition: bottom 0.35s;

  -moz-border-radius: 20px;
  -webkit-border-radius: 20px;
  -khtml-border-radius: 20px;
  border-radius: 20px;

  position: absolute;
  width: 90%;

  padding: 20px 5% 50px 5%;

  bottom: ${({ show }) => (show ? "90%" : "20%")};

  left: 0;
  z-index: 0;

  background: ${({ theme }) => theme.alert.background.error};
`;

export const ErrorMessage = styled.div`
  position: relative;

  font-size: 9em;
`;

export const ErrorTitle = styled.h5`
  width: 100%;
  font-weight: 700;
  line-height: 1.2;
  color: ${({ theme }) => theme.alert.text.title};
  font-size: 1.2em;
`;

export const ErrorSubtitle = styled.h6`
  width: 100%;
  font-weight: 700;
  line-height: 1.1;
  color: ${({ theme }) => theme.alert.text.subtitle};
  font-size: 1em;
  margin-top: 2px;
`;

export const LoaderContainer = styled.div`
  -webkit-transition: opacity 0.35s;
  -moz-transition: opacity 0.35s;
  -o-transition: opacity 0.35s;
  transition: opacity 0.35s;

  position: absolute;
  top: 50%;
  left: 50%;

  width: 40px;
  height: 40px;
  margin-top: -20px;
  margin-left: -20px;

  opacity: ${({ show }) => (show ? "1" : "0")};
`;

export const LoaderSVG = styled.svg`
  -webkit-animation: rotate 2s linear infinite;
  -moz-animation: rotate 2s linear infinite;
  -ms-animation: rotate 2s linear infinite;
  -o-animation: rotate 2s linear infinite;
  animation: rotate 2s linear infinite;

  width: 100%;
  height: 100%;
`;

export const LoaderCircle = styled.circle`
  -webkit-animation: spinner 1.5s ease-in-out infinite;
  -moz-animation: spinner 1.5s ease-in-out infinite;
  -ms-animation: spinner 1.5s ease-in-out infinite;
  -o-animation: spinner 1.5s ease-in-out infinite;
  animation: spinner 1.5s ease-in-out infinite;

  stroke: ${({ theme }) => theme.authorize.input.background};
  stroke-linecap: round;
  stroke-dasharray: 90, 150;
  stroke-dashoffset: 0;
`;
