import styled from "styled-components";

export const Entity = styled.button`
  float: right;
  background: ${({ theme }) =>
    theme.body.header.company.button.background.default};
  padding: 12px 50px 12px 20px;
  margin-right: -40px;
  -moz-border-radius: 100px;
  -webkit-border-radius: 100px;
  -khtml-border-radius: 100px;
  border-radius: 100px;
  color: ${({ theme }) => theme.body.header.company.button.text};
  font-size: 11pt;
  font-weight: 700;
  text-transform: capitalize;
  cursor: default;
  z-index: 151;

  /*
  &:first-line {
    text-transform: capitalize;
  }*/
`;
