import styled from "styled-components";

export const ErrorContainer = styled.div`
  position: fixed;
  float: none;
  clear: both;
  top: 50%;
  left: 50%;
  width: 500px;
  height: 400px;
  pointer-events: auto;
  overflow: auto;
  //background: green;
  margin: -160px 0 0 -250px;
`;

export const ErrorIcon = styled.div`
  position: absolute;
  float: none;
  clear: both;
  top: 50px;
  left: 50%;
  width: 30px;
  display: inline-block;

  margin-top: 0;
  margin-left: -60px;
  color: ${({ theme }) => theme.sidebar.background};
  font-size: 120px;
  line-height: 120px;
  font-family: "${({ theme }) => theme.font.icon}";
  font-weight: 700;
  text-align: center;
  &::before {
    width: 100%;
    height: 100%;
    content: '\\f071'};
  }
`;

export const ErrorMessage = styled.div`
  position: absolute;
  float: none;
  clear: both;
  top: 200px;
  width: 90%;
  padding: 0 5%;
  text-align: center;
  font-size: 12em;
  font-weight: 700;
`;

export const LoaderContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100px;
  height: 300px;
  margin: -150px 0 0 -50px;
`;

export const LoaderSVG = styled.svg`
  -webkit-animation: rotate 2s linear infinite;
  -moz-animation: rotate 2s linear infinite;
  -ms-animation: rotate 2s linear infinite;
  -o-animation: rotate 2s linear infinite;
  animation: rotate 2s linear infinite;

  width: 100%;
  height: 100%;
`;

export const LoaderCircle = styled.circle`
  -webkit-animation: spinner 1.5s ease-in-out infinite;
  -moz-animation: spinner 1.5s ease-in-out infinite;
  -ms-animation: spinner 1.5s ease-in-out infinite;
  -o-animation: spinner 1.5s ease-in-out infinite;
  animation: spinner 1.5s ease-in-out infinite;

  stroke: ${({ theme }) => theme.body.loader};
  stroke-linecap: round;
  stroke-dasharray: 90, 150;
  stroke-dashoffset: 0;
`;

export const LoaderMessage = styled.div`
  position: absolute;
  float: none;
  clear: both;
  top: 57%;
  left: 0;
  width: 90%;
  padding: 0 5%;
  text-align: center;
  font-size: 12em;
  font-weight: 700;
  //color: ${({ theme }) => theme.body.loader};
`;
