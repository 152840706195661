/*
 * * * * * * * * * * * * * * * * * * * * * * * *
 * COLOR PALETTE
 * * * * * * * * * * * * * * * * * * * * * * * *

  Blue:
   - Navy: rgb(6, 82, 221)
   - Prussian: rgb(0, 45, 105)

  Green:
   - Yellow: rgb(163, 203, 56)
   - Pigment: rgb(0, 148, 50)

  Black: rgb(0, 13, 30)
  White: rgb(255, 255, 255)

  */
const uni_palette = {
  blue: {
    navy: "6, 82, 221",
    prussian: "0, 45, 105",
  },
  green: {
    yellow: "163, 203, 56",
    pigment: "0, 148, 50",
  },
  white: "255, 255, 255",
  black: "0, 13, 30",
};

/*
 * Light theme setup
 */
const light = {
  font: {
    default: "Roboto",
    icon: "Font Awesome 5 Pro",
  },

  sidebar: {
    background: "rgb(0, 45, 105)",
    width: {
      pixel: "340",
      percent: "30",
    },

    filter: {
      title: "rgb(" + uni_palette.white + ")",
      item: {
        selected: {
          background: "rgb(" + uni_palette.blue.navy + ")",
          text: "rgb(" + uni_palette.white + ")",
        },
        disabled: {
          background: "rgba(" + uni_palette.white + ", 0.7)",
          //text: "rgb(" + uni_palette.white + ")",
          text: "rgba(" + uni_palette.black + ", 0.8)",
        },
        default: {
          background: "rgb(" + uni_palette.white + ")",
          text: "rgba(" + uni_palette.black + ", 0.8)",
        },
      },
    },

    search: {
      box: {
        background: "rgb(255, 255, 255)",
        text: "rgba(0, 13, 30, 0.8)",
        icon: "rgba(0, 13, 30, 0.3)",
        placeholder: "rgba(0, 13, 30, 0.3)",
        loader: "rgba(0, 13, 30, 0.3)",
      },

      suggestions: {
        background: "rgb(6, 82, 221)",
        //hover: "rgba(0, 13, 30, 0.2)",
        hover: "rgba(0, 45, 105, 0.4)",
        text: {
          different: "rgb(255, 255, 255)",
          match: "rgba(255, 255, 255, 0.8)",
        },
      },
    },
  },

  authorize: {
    input: {
      text: "rgba(0, 13, 30, 0.8)",
      background: "rgb(255, 255, 255)",
    },
    button: {
      text: "rgb(255, 255, 255)",
      background: {
        default: "rgb(6, 82, 221)",
      },
    },
  },

  header: {
    height: {
      pixel: "70",
      percent: "12",
    },
    background: "rgb(255, 255, 255)",
  },

  body: {
    loader: "rgba(0, 13, 30, 0.2)",
    home: {
      recent: {
        title: "rgba(0, 13, 30, 0.8)",
        chip: {
          background: {
            default: "rgb(" + uni_palette.blue.prussian + ")",
            hover: "rgb(" + uni_palette.blue.navy + ")",
            //active: "rgba(" + uni_palette.black + ", 0.3)",
          },
          text: "rgb(255, 255, 255)",
        },
      },
    },
    search: {
      buttonSupplierStock: {
        background: {
          default: "rgba(" + uni_palette.black + ", 0.1)",
          hover: "rgba(" + uni_palette.black + ", 0.2)",
          active: "rgba(" + uni_palette.black + ", 0.3)",
        },
        text: {
          default: "rgba(" + uni_palette.black + ", 0.8)",
        },
      },
      list: {
        item: {
          pendant: {
            text: "rgba(" + uni_palette.black + ", 0.8)",
            background: "rgba(" + uni_palette.black + ", 0.1)",
          },
          background: "rgb(255, 255, 255)",
          stock: {
            available: {
              background: "rgb(" + uni_palette.blue.navy + ")",
              text: "rgb(" + uni_palette.white + ")",
            },
            hover: {
              background: "rgb(" + uni_palette.blue.prussian + ")",
              text: "rgb(" + uni_palette.white + ")",
            },
            supplier: {
              background: "rgba(" + uni_palette.black + ", 0.1)",
              text: "rgba(" + uni_palette.black + ", 0.8)",
            },
          },
        },
      },
    },
    header: {
      user: {
        button: {
          background: {
            default: "rgb(" + uni_palette.blue.prussian + ")",
            hover: "rgb(" + uni_palette.blue.navy + ")",
          },
          text: "rgb(" + uni_palette.white + ")",
        },
      },
      company: {
        button: {
          background: {
            default: "rgba(" + uni_palette.black + ", 0.1)",
          },
          text: "rgba(" + uni_palette.black + ", 0.8)",
        },
      },
    },
  },

  page: {
    background: "rgba(0, 13, 30, 0.07)",
    text: {
      global: "rgba(0, 13, 30, 0.8)",
    },

    selection: {
      text: "rgb(0, 45, 105)",
      background: "rgb(163, 203, 56)",
    },
  },

  alert: {
    background: {
      info: "rgb(6, 82, 221)",
      error: "#f56342",
      success: "rgb(0, 148, 50)",
    },
    icon: "rgb(255, 255, 255)",
    text: {
      title: "rgb(255, 255, 255)",
      subtitle: "rgba(255, 255, 255, 0.8)",
    },
  },
};

export default light;
