import React from "react";

import {
  FilterListItem,
  Name,
  OptionList,
  OptionListItem,
  OptionCheckbox,
  OptionLabel,
  OptionLabelName,
} from "./SidebarFilterStyle";

const SidebarFilter = (props) => {
  return (
    <FilterListItem>
      <Name>{props.filter.name}</Name>
      <OptionList>
        {[...props.filter.list]
          .sort((a, b) => a.order - b.order)
          .map((i) => (
            <OptionListItem key={i.match}>
              <OptionLabel
                htmlFor={props.type + i.match}
                isDisabled={i.disabled}
                isChecked={i.selected}
              >
                <OptionCheckbox
                  type="checkbox"
                  id={props.type + i.match}
                  disabled={i.disabled}
                  defaultChecked={i.selected}
                  onChange={(e) => {
                    props.handleChange({
                      match: i.match,
                      type: props.type,
                      checked: e.target.checked,
                    });
                  }}
                />
                <OptionLabelName>{i.name}</OptionLabelName>
              </OptionLabel>
            </OptionListItem>
          ))}
      </OptionList>
    </FilterListItem>
  );
};

export default SidebarFilter;
