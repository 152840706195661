import { Container, List, Item, Button, Bold } from "./SuggestionsStyle";

const Suggestions = (props) => {
  const common = props.suggestions.c;
  return (
    <Container
      onMouseEnter={props.handleSuggestionShow}
      onMouseLeave={props.handleSuggestionHide}
    >
      <List>
        {props.suggestions.m.map(({ i, k }) => (
          <Item key={k}>
            <Button
              onClick={() => props.handleClick({ k, n: i.p + common + i.s })}
            >
              {i.p} <Bold count={props.suggestions.m.length}>{common}</Bold>
              {i.s}
            </Button>
          </Item>
        ))}
      </List>
    </Container>
  );
};

export default Suggestions;
