import styled from "styled-components";

export const User = styled.button`
  float: right;
  background: ${({ theme }) =>
    theme.body.header.user.button.background.default};
  padding: 12px 20px;
  -moz-border-radius: 100px;
  -webkit-border-radius: 100px;
  -khtml-border-radius: 100px;
  border-radius: 100px;
  color: ${({ theme }) => theme.body.header.user.button.text};
  font-size: 11pt;
  font-weight: 700;
  z-index: 152;
  &:hover {
    background: ${({ theme }) =>
      theme.body.header.user.button.background.hover};
  }
`;
