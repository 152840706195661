import styled from "styled-components";

export const FilterListItem = styled.li`
  width: 100%;
  margin: 15px 0 0 0;
  &:first-child {
    margin: 10px 0 0 0;
  }
  &:last-child {
    margin: 10px 0 15px 0;
  }
`;

export const Name = styled.div`
  width: 90%;
  margin: 0 0 10px 10%;

  font-size: 10em;
  font-weight: 700;
  text-transform: uppercase;
  color: white;
`;

export const OptionList = styled.ul`
  width: 90%;
  margin: 0 5%;
  float: left;
  //background: red;
`;

export const OptionListItem = styled.li`
  margin: 0 5px 5px 0;
`;

export const OptionCheckbox = styled.input`
  background: green;
  visibility: collapse;
  width: 0px;
  height: 0px;
  //margin: 0 5px 0 15px;
  //margin: 0 5px 0 0;

  cursor: pointer;
`;

export const OptionLabel = styled.label`
  pointer-events: auto;
  cursor: ${({ isDisabled }) => (isDisabled ? "default" : "pointer")};

  padding: 5px 15px;

  -moz-border-radius: 100px;
  -webkit-border-radius: 100px;
  -khtml-border-radius: 100px;
  border-radius: 100px;

  background: ${({ isChecked, isDisabled, theme }) =>
    isDisabled
      ? theme.sidebar.filter.item.selected.background
      : isChecked
      ? theme.sidebar.filter.item.selected.background
      : theme.sidebar.filter.item.default.background};

  color: ${({ isChecked, isDisabled, theme }) =>
    isDisabled
      ? theme.sidebar.filter.item.selected.text
      : isChecked
      ? theme.sidebar.filter.item.selected.text
      : theme.sidebar.filter.item.default.text};
`;

export const OptionLabelName = styled.div`
  //margin: 0 15px 0 0;
  font-size: 9em;
  line-height: 2em;
  font-weight: 700;
  width: 100%;
  text-rendering: optimizeLegibility;

  color: inherit;
`;

/*
  &:after {
    content: "";
    position: absolute;
    top: 1px;
    left: 5px;
    width: 5px;
    height: 11px;
    opacity: 0;
    transform: rotate(45deg) scale(0);

    transition: all 0.3s ease;
    transition-delay: 0.15s;
  }
  &:checked {
    border-color: transparent;
    background: #6871f1;
    animation: jelly 0.6s ease;
  }*/
