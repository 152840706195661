import React from "react";
import { connect } from "react-redux";

import { Entity } from "./HeaderEntityStyle";

class HeaderEntity extends React.Component {
  getEntity = () => {
    if (this.props.entity[0]) {
      return (
        <Entity id={this.props.entity[0].k}>{this.props.entity[0].n}</Entity>
      );
    } else {
      return null;
    }
  };

  render() {
    return this.getEntity();
  }
}

export default connect(
  ({ authenticator }) => ({
    entity: authenticator.entity,
  }),
  null
)(HeaderEntity);
