import React from "react";
import { connect } from "react-redux";

import {
  Container,
  LoaderContainer,
  LoaderSVG,
  LoaderCircle,
  BottomSpacer,
} from "./BodyStyle";

import Home from "./Home/Home";
import Search from "./Search/Search";

import { getCurated } from "../../../redux/store/body";
import { showPointer } from "../../../redux/store/search";

class Body extends React.Component {
  handleCurated = () => {
    /*
     * Check if the curated list needs to be updated
     * Dispatch a request if it needs
     */
    if (this.props.bodyHomeCuratedGet) {
      this.props.dispatch(getCurated());
    }
  };

  handleContent() {
    if (this.props.bodyLoading) {
      return (
        <LoaderContainer>
          <LoaderSVG viewBox="0 0 60 60">
            <LoaderCircle
              cx="30"
              cy="30"
              r="20"
              fill="none"
              strokeWidth="8"
            ></LoaderCircle>
          </LoaderSVG>
        </LoaderContainer>
      );
    } else {
      switch (true) {
        case this.props.bodySearchList.length > 0 || this.props.bodySearchEmpty:
          this.props.dispatch(showPointer(false));
          return <Search />;
        default:
          return <Home />;
      }
    }
  }

  render() {
    this.handleCurated();
    return (
      <React.Fragment>
        <Container>{this.handleContent()}</Container>
        <BottomSpacer />
      </React.Fragment>
    );
  }
}

export default connect(({ body }) => ({
  bodySearchList: body.search.list,
  bodySearchEmpty: body.search.empty,
  bodyLoading: body.loading,
  bodyHomeCuratedGet: body.home.curated.get,
}))(Body);
