import styled from "styled-components";

export const Container = styled.div`
  -webkit-transition: all 10ms ease-in-out;
  -moz-transition: all 10ms ease-in-out;
  -o-transition: all 10ms ease-in-out;
  transition: all 10ms ease;
  position: absolute;
  float: none;
  clear: both;
  display: block;
  //top: ${(props) => (props.toggle ? "0" : "10px")};
  left: 5%;
  overflow: auto;
  //width: ${(props) => (props.toggle ? "auto" : "0")};
  //height: ${(props) => (props.toggle ? "auto" : "0")};
  background: ${({ theme }) => theme.sidebar.search.suggestions.background};
  -moz-border-radius: 28px;
  -webkit-border-radius: 28px;
  -khtml-border-radius: 28px;
  border-radius: 28px;
  //opacity: ${(props) => (props.toggle ? "1" : "0")};
  // Solves the onMouseOver problem
  pointer-events: auto;
  z-index: 310;
`;

export const List = styled.ul`
  margin: 60px 0 2% 0;
  min-width: 400px;
`;

export const Item = styled.li`
  width: 100%;
  float: none;
  clear: both;
  & > button:hover {
    background: ${({ theme }) => theme.sidebar.search.suggestions.hover};
  }
`;

export const Button = styled.button`
  -webkit-transition: background 500ms ease-in-out;
  -moz-transition: background 500ms ease-in-out;
  -o-transition: background 500ms ease-in-out;
  transition: background 300ms ease-in-out;
  width: 100%;
  padding: 2.5% 0 2.5% 11%;
  font-size: 10em;
  font-weight: 700;
  text-align: left;
  cursor: pointer;
  color: ${({ theme }) => theme.sidebar.search.suggestions.text.different};
  pointer-events: auto;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  -khtml-border-radius: 0;
  border-radius: 0;
`;

export const Bold = styled.span`
  font-weight: 700;
  white-space: pre-wrap;
  color: ${({ count, theme }) =>
    count > 1
      ? theme.sidebar.search.suggestions.text.match
      : theme.sidebar.search.suggestions.text.different};
`;
