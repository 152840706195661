import React from "react";
import { connect } from "react-redux";
import ReCAPTCHA from "react-google-recaptcha";

import { authorize } from "../../redux/store/authenticator";
import { alertError } from "../../redux/store/alert";

import {
  Container,
  Form,
  Logo,
  LogoObject,
  List,
  ItemInput,
  Input,
  Button,
  ErrorContainer,
  ErrorMessage,
  ErrorTitle,
  ErrorSubtitle,
  LoaderContainer,
  LoaderSVG,
  LoaderCircle,
} from "./AuthorizeStyle";

import {
  authorizeError,
  authorizeErrorClear,
  wiggleBox,
  buttonLoader,
} from "../../redux/store/authenticator";

import logoSVG from "../Sidebar/Header/partner.svg";

class Authorize extends React.Component {
  constructor(props, ...args) {
    super(props, ...args);
    this.captchaRef = React.createRef();
    this.state = {
      captchaLoaded: false,
      emailSet: false,
      emailValid: true,
      passwordSet: false,
      passwordValid: true,
      tokenValid: false,
      wiggleButton: false,
      loading: false,
    };
  }

  onCaptchaError = (event) => {
    console.log("onCaptchaError", { event });
    this.setState({ captchaLoaded: false });

    const alert = {
      title: "Настана грешка при валидација на пребарувачот!",
      description:
        "Пробај повторно... Доколку пак не успееш пријави го овој проблем.",
      icon: "f06a",
      expire: false,
    };

    // console.log("test alert", alert); //log
    this.props.dispatch(alertError(alert));
  };

  onCaptchaLoad = (event) => {
    //console.log("onCaptchaLoad", { event }, this.captchaRef);
    this.setState({ captchaLoaded: true });
  };

  validateEmail(email) {
    const regexp =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regexp.test(email);
  }

  handleEmail = (input) => {
    if (input.length > 0) {
      this.setState({ emailSet: true });
    } else {
      console.log("Please enter email...");
      this.setState({ emailSet: false });
    }
  };

  handlePassword = (input) => {
    if (input.length > 0) {
      this.setState({ passwordSet: true });
    } else {
      this.setState({ passwordSet: false });
    }
  };

  wiggle = () => {
    this.props.dispatch(wiggleBox(false));
    setTimeout(() => this.props.dispatch(wiggleBox(true)), 1);
  };

  handleSubmit = async (form) => {
    console.log("handleSubmit");
    form.preventDefault();

    this.props.dispatch(buttonLoader(true));

    const { email, password } = form.target.elements;

    // Check if both were set
    if (!this.state.emailSet && !this.state.passwordSet) {
      this.props.dispatch(buttonLoader(false));
      this.setState({ emailValid: false, passwordValid: false });
      this.props.dispatch(
        authorizeError({ title: "Внесете e-mail адреса и лозинка..." })
      );
      this.wiggle();
      return;
    }

    // Validate the email
    if (this.state.emailSet) {
      if (!this.validateEmail(email.value)) {
        this.props.dispatch(buttonLoader(false));
        this.setState({ emailValid: false });
        this.props.dispatch(
          authorizeError({ title: "Внeсената e-mail адреса не е валидна!" })
        );
        this.wiggle();
        return;
      } else {
        this.props.dispatch(buttonLoader(true));
        this.setState({ emailValid: true });
        this.props.dispatch(authorizeErrorClear());
      }
    } else {
      this.props.dispatch(buttonLoader(false));
      this.setState({ emailValid: false });
      this.props.dispatch(
        authorizeError({ title: "Внесете e-mail адреса..." })
      );
      this.wiggle();
      return;
    }

    // Validate the password
    if (this.state.passwordSet) {
      if (password.value.length > 7) {
        this.props.dispatch(buttonLoader(true));
        this.setState({ passwordValid: true });
        this.props.dispatch(authorizeErrorClear());
      } else {
        this.props.dispatch(buttonLoader(false));
        this.setState({ passwordValid: false });
        this.props.dispatch(
          authorizeError({ title: "Внесената лозинка не е валидна!" })
        );
        this.wiggle();
        return;
      }
    } else {
      this.props.dispatch(buttonLoader(false));
      this.setState({ passwordValid: false });
      this.props.dispatch(authorizeError({ title: "Внесете лозинка..." }));
      this.wiggle();
      return;
    }

    var token = "";
    try {
      // Reset the previous token
      this.captchaRef.current.reset();

      // Fetching new token
      token = await this.captchaRef.current.executeAsync();

      this.setState({ loading: true, tokenValid: true });

      this.props.dispatch(buttonLoader(true));

      if (
        this.state.emailSet &&
        this.state.emailValid &&
        this.state.passwordSet &&
        this.state.passwordValid &&
        token.length > 0
      ) {
        console.log("Logging in...");
        this.props.dispatch(
          authorize({
            email: email.value,
            password: password.value,
            captcha: token,
          })
        );
      } else {
        console.log("Failed to log in!");
        console.log("state", this.state);
        this.props.dispatch(buttonLoader(false));
        this.props.dispatch(
          authorizeError({
            title: "Настана грешка!",
            subtitle:
              "Пробај повторно... Доколку пак не успееш пријави го овој проблем.",
          })
        );
        this.wiggle();
      }
    } catch (e) {
      console.error("Captcha Error", { e });
      this.props.dispatch(buttonLoader(false));
      this.setState({ tokenValid: false });
      this.props.dispatch(
        authorizeError({
          title: "Настана грешка при валидација на пребарувачот!",
          subtitle:
            "Пробај повторно... Доколку пак не успееш пријави го овој проблем.",
        })
      );
      this.wiggle();
      return;
    }

    /*
    if (token.length <= 0) {
      this.props.dispatch(buttonLoader(false));
      this.setState({ tokenValid: false });
      this.props.dispatch(
        authorizeError({
          title: "Настана грешка при валидација на пребарувачот!",
          subtitle:
            "Пробај повторно... Доколку пак не успееш пријави го овој проблем.",
        })
      );
      this.wiggle();
      //return;
    }

    if (
      this.state.emailSet &&
      this.state.emailValid &&
      this.state.passwordSet &&
      this.state.passwordValid &&
      //(this.state.tokenValid || token.length > 0)
      this.state.tokenValid
    ) {
      console.log("Logging in...");
      this.props.dispatch(
        authorize({
          email: email.value,
          password: password.value,
          captcha: token,
        })
      );
    } else {
      console.log("Failed to log in!");
      console.log("state", this.state);
      this.props.dispatch(buttonLoader(false));
      this.props.dispatch(
        authorizeError({
          title: "Настана грешка!",
          subtitle:
            "Пробај повторно... Доколку пак не успееш пријави го овој проблем.",
        })
      );
      this.wiggle();
    }*/
  };

  render() {
    return (
      <React.Fragment>
        <Container className={this.props.authorizeWiggle ? "a" : ""}>
          <ErrorContainer
            show={
              this.props.authorizeError.show &&
              this.props.authorizeError.title.length > 0
            }
          >
            <ErrorMessage>
              <ErrorTitle>{this.props.authorizeError.title}</ErrorTitle>
              {this.props.authorizeError.subtitle.length > 0 ? (
                <ErrorSubtitle>
                  {this.props.authorizeError.subtitle}
                </ErrorSubtitle>
              ) : null}
            </ErrorMessage>
          </ErrorContainer>
          <Form onSubmit={this.handleSubmit} noValidate formNoValidate>
            <Logo>
              <LogoObject
                type="image/svg+xml"
                data={logoSVG}
                aria-label="PARTNER"
              />
            </Logo>
            <List>
              <ItemInput valid={this.state.emailValid}>
                <Input
                  type="email"
                  placeholder="Е-пошта"
                  name="email"
                  autoComplete="username"
                  onChange={(e) => this.handleEmail(e.target.value)}
                  //onInput={(e) => this.handleEmail(e.target.value)}
                ></Input>
              </ItemInput>
              <ItemInput valid={this.state.passwordValid}>
                <Input
                  type="password"
                  placeholder="Лозинка"
                  name="password"
                  autoComplete="current-password"
                  onChange={(e) => this.handlePassword(e.target.value)}
                  //onInput={(e) => this.handlePassword(e.target.value)}
                ></Input>
              </ItemInput>
            </List>
            <Button disabled={this.props.authorizeButton.loading}>
              <LoaderContainer show={this.props.authorizeButton.loading}>
                <LoaderSVG viewBox="0 0 60 60">
                  <LoaderCircle
                    cx="30"
                    cy="30"
                    r="20"
                    fill="none"
                    strokeWidth="8"
                  ></LoaderCircle>
                </LoaderSVG>
              </LoaderContainer>
              Најави се
            </Button>
          </Form>
        </Container>

        <ReCAPTCHA
          style={{
            position: "absolute",
            visibility: "hidden",
            opacity: "0",
            pointerEvents: "auto",
          }}
          ref={this.captchaRef}
          size="invisible"
          badge="inline"
          data-callback="callback"
          sitekey={global.config.captcha.key}
          onErrored={this.onCaptchaError}
          asyncScriptOnLoad={this.onCaptchaLoad}
        />
      </React.Fragment>
    );
  }
}

export default connect(
  ({ authenticator }) => ({
    authenticated: authenticator.authenticated,
    authorizeWiggle: authenticator.authorize.wiggle,
    authorizeError: authenticator.authorize.error,
    authorizeButton: authenticator.authorize.button,
  }),
  null
)(Authorize);
