import React from "react";
import { connect } from "react-redux";

import {
  List,
  SupplierStockButtonContainer,
  SupplierStockButtonButton,
  ErrorContainer,
  FilterIcon,
  EmptyIcon,
  ErrorMessage,
} from "./SearchStyle";

import SearchItem from "./SearchItem/SearchItem";

import {
  getSupplierStock,
  filterUpdated,
  goHomeStatus,
} from "../../../../redux/store/body";

class Search extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showSupplierStock: false,
    };
  }

  // Makes a request to get stock from supplier for items not in stock
  handleSupplierStock() {
    if (
      this.props.supplierStockCode.length > 0 &&
      this.props.supplierStockList.length === 0
    ) {
      this.props.dispatch(getSupplierStock(this.props.supplierStockCode));
    }
  }

  // Handles the show the items not in stock button is clicked
  handleSupplierStockButton = () => {
    this.setState({ showSupplierStock: true });
  };

  componentDidMount() {
    /*
     * Check if searchAvailableStock is available
     * If not get supplier stock
     */
    if (
      !this.props.searchAvailableStock &&
      this.props.supplierStockCode.length > 0 &&
      this.props.supplierStockList.length === 0
    ) {
      this.handleSupplierStock();
    }

    // Show the go back home button if it's not shown
    if (!this.props.bodyShowGoHome) {
      this.props.dispatch(goHomeStatus({ show: true }));
    }
  }

  componentDidUpdate(prevProps, prevState) {
    // Check if button was clicked in order to show the additional supplier stock
    if (
      prevState.showSupplierStock === false &&
      this.state.showSupplierStock === true
    ) {
      if (this.state.showSupplierStock) {
        this.props.dispatch(
          filterUpdated({
            match: "F",
            type: "stock",
            checked: true,
          })
        );
        this.handleSupplierStock();
      }
    }

    // Check if a filter has caused an empty listFiltered
    // If so then display additional stock items
    if (
      this.props.searchListFiltered.length === 0 &&
      this.props.searchList.length > 0 &&
      this.state.showSupplierStock !== true
    ) {
      this.setState({ showSupplierStock: true });
    }
  }

  // Generates the show items not in stock button
  supplierStockButton = () => {
    if (
      this.props.searchListFiltered.length > 0 &&
      typeof this.props.searchFilter.stock.list != "undefined" &&
      this.props.searchAvailableStock
    ) {
      const index = this.props.searchFilter.stock.list
        .map((e) => e.match)
        .indexOf("F");

      const item = this.props.searchFilter.stock.list[index];
      if (item) {
        const count = item.count;
        const selected = item.selected;
        if (!selected) {
          if (count === 1) {
            return (
              <SupplierStockButtonContainer>
                <SupplierStockButtonButton
                  onClick={this.handleSupplierStockButton}
                >
                  Прикажи уште {count} производ
                </SupplierStockButtonButton>
              </SupplierStockButtonContainer>
            );
          } else {
            return (
              <SupplierStockButtonContainer>
                <SupplierStockButtonButton
                  onClick={this.handleSupplierStockButton}
                >
                  Прикажи уште {count} производи
                </SupplierStockButtonButton>
              </SupplierStockButtonContainer>
            );
          }
        }
      }
    }
  };

  // Generates the main item list
  searchItems = () => {
    if (this.props.searchListFiltered.length > 0) {
      return (
        <List>
          {this.props.searchListFiltered.map((item) => (
            <SearchItem key={item.k + item.d.c} item={item} />
          ))}
        </List>
      );
    } else {
      if (this.props.searchList.length === 0 && this.props.bodySearchEmpty) {
        return (
          <ErrorContainer>
            <EmptyIcon />
            <ErrorMessage>Ништо не е пронајдено.</ErrorMessage>
          </ErrorContainer>
        );
      } else {
        return (
          <ErrorContainer>
            <FilterIcon />
            <ErrorMessage>Нема ваква комбинација!</ErrorMessage>
          </ErrorContainer>
        );
      }
    }
  };

  render() {
    return (
      <React.Fragment>
        {this.searchItems()}
        {this.supplierStockButton()}
      </React.Fragment>
    );
  }
}

export default connect(({ body }) => ({
  bodyShowGoHome: body.showGoHome,
  searchList: body.search.list,
  bodySearchEmpty: body.search.empty,
  searchListFiltered: body.search.listFiltered,
  searchFilter: body.search.filter,
  searchAvailableStock: body.search.availableStock,
  supplierStockCode: body.search.supplierStock.code,
  supplierStockList: body.search.supplierStock.list,
  supplierStockUpdated: body.search.supplierStock.updated,
}))(Search);
