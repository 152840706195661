import axios from "axios";
import { createAction } from "@reduxjs/toolkit";

import { alertError } from "../store/alert";
import { clearSession, globalError } from "../store/authenticator";

const authenticationFailedReferences = [2190];

export const partnerApiStart = createAction("UNI/API/started");

const api = (store) => (next) => async (action) => {
  if (action.type !== partnerApiStart.type) {
    return next(action);
  }
  //next(action);

  /*
   * Extract from the action's payload

   * url - Specific endpoint
   * method - GET, POST, PUT etc..
   * authorize - Adds specific header required for logging in with base64 encoded email and password
   * authenthicate - Adds the JWT token from local storage
   * authenthicateRequired - Paired with the state will not allow a request without authentication
   * data - Payload required by the backend
   * pass - Payload to be returned by onStart, onSuccess or onError
   * onStart - Will run before the request
   * onSuccess - Will run after a sucessful request
   * expectedStatus -  Expected status codes
   * onError - Will run after an unsucessful request
   */
  const {
    url,
    method,
    data,
    pass,
    authorize,
    authenthicate,
    authenthicateRequired,
    timeout,
    captcha,
    onStart,
    onSuccess,
    expectedError,
    onError,
  } = action.payload;

  //console.log(action.payload); //log

  /*
   * Check if there is an action thats required to dispatch before the request
   * and run it
   */
  if (onStart) {
    store.dispatch({ type: onStart, pass });
  }

  /*
   * Check if state is tokenized
   * Check if authentication is required
   * If it is and there is no token
   * Will skip the action
   */
  if (
    !store.getState().authenticator.tokenized &&
    authenthicateRequired === true
  ) {
    return; // Will skip the action
  }

  /*
   * Check stated if tokenized
   * Make sure authentication is not disabled
   * Rule out authorizations
   * And will append the JWT token to the authenticate header
   */
  if (
    store.getState().authenticator.tokenized &&
    authenthicate !== false &&
    !authorize
  ) {
    axios.defaults.headers.common["uni-authenticate"] = localStorage.getItem(
      global.config.storage.session
    );
  }

  /*
   * Only sent with an authorization (logging in) reguest
   * Checks if set
   * Applies base63 encoding
   * And will append the appropriate authorize header
   */
  if (authorize) {
    axios.defaults.headers.common["uni-authorize"] = btoa(
      unescape(encodeURIComponent(authorize.email + ":" + authorize.password))
    );
  } else {
    if ("uni-authorize" in axios.defaults.headers.common) {
      delete axios.defaults.headers.common["uni-authorize"];
    }
  }

  /*
   * Checks if set
   * Applies base63 encoding
   * And will append the captcha header with the token as payload
   */
  if (captcha) {
    axios.defaults.headers.common["uni-captcha"] = btoa(
      unescape(encodeURIComponent(captcha))
    );
  } else {
    if ("uni-captcha" in axios.defaults.headers.common) {
      delete axios.defaults.headers.common["uni-captcha"];
    }
  }

  /*
   * Return to the default if custom timeout was not set
   */
  var setTimeout = 0;
  if (timeout) {
    setTimeout = timeout;
  }

  try {
    /*
     * Setting up and starting the request
     */
    const response = await axios.request({
      //validateStatus: function (status) { return status >= 200 && status < 300; },
      baseURL: global.config.api.partner,
      url,
      method,
      headers: {
        "content-type": "application/json; charset=utf-8",
        accept: "application/json",
      },
      data,
      timeout: setTimeout,
    });

    /*
     * Checks if an onSuccess action
     * And dispatches it
     */
    if (onSuccess) {
      if (onSuccess instanceof Array) {
        onSuccess.forEach(function (item, index) {
          store.dispatch({
            type: item,
            data: response.data,
            status: response.status,
          });
        });
      } else {
        store.dispatch({
          type: onSuccess,
          data: response.data,
          status: response.status,
        });
      }
    }

    // Catch any errors
  } catch (error) {
    /*
     * The request was made and a response was received by the server
     */
    if (error.response) {
      console.log(error.response.data);
      console.log(error.response.status);
      console.log(error.response.headers);

      const errorTittle =
        error.response.data.e.m ||
        "Настана непозната грешка при комуникација на серверот!";

      /*
       * Check if authentication FAILED
       * Only when authentication IS REQUIRED
       */
      if (
        authenthicateRequired && // authenthicateRequired maybe add "undefined" check as well?
        error.response.data.e.r &&
        authenticationFailedReferences.includes(error.response.data.e.r)
      ) {
        /*
         * Return to login page
         */
        //console.log("Return to login page..."); //log
        store.dispatch(clearSession()); // Will clear the session
        delete axios.defaults.headers.common["uni-authenticate"]; // And delete the authenticate header from axios
        return; // Will skip the action

        /*
         * When authentication is NOT required
         * Will return an error alert
         */
      } else {
        /*
         * Check if expectedStatus is set
         * Check if the current status is expected
         */
        if (expectedError && expectedError.includes(error.response.status)) {
          /*
           * Check if an onError action exits
           */
          if (onError) {
            store.dispatch({
              type: onError,
              payload: error.message,
              status: error.response.status,
              data: error.response.data || "",
            });
          }
          /*
           * Otherwise post an alert
           */
        } else {
          const alert = {
            title: errorTittle,
            icon: "f06a",
            expire: false,
          };
          if (error.response.data.e.r) {
            alert["description"] = "Референца " + error.response.data.e.r;
          }
          store.dispatch(alertError(alert));
        }
      }

      /*
       * The request was made but no response was received, `error.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
    } else if (error.request) {
      console.log("Error:", error);

      store.dispatch(
        globalError({
          message: "Неможе да се оствари конекција со серверот!",
        })
      );

      return;

      /*
       * Something happened in setting up the request and triggered an Error
       */
    } else {
      console.log(
        "Something happened in setting up the request and triggered an error."
      );
      console.log("Error:", error);

      const alert = {
        title: "Настана непозната грешка при комуникација на серверот!",
        icon: "f06a",
        expire: false,
      };
      if (error === "NetworkError") {
        alert["description"] = "Неуспешна конекција со серверот!";
      }
      store.dispatch(alertError(alert));

      return;
    }

    /*
     * Error without request
     * Prbably we weren't able to contact the server
     */
    console.log("Error without request:", error);

    const alert = {
      title: "Настана непозната грешка при комуникација на серверот!",
      icon: "f06a",
      expire: false,
    };
    store.dispatch(alertError(alert));
  }
};

export default api;
