import styled from "styled-components";
import { useTheme } from "styled-components";

const HandleColor = (type) => {
  const theme = useTheme();

  switch (type) {
    case 2:
      return theme.alert.background.success;
    case 3:
      return theme.alert.background.error;
    default:
      return theme.alert.background.info;
  }
};

export const Separator = styled.li`
  width: 100%;
`;

export const Container = styled.div`
  //min-width: 280px;
  //max-width: 400px;
  float: right;
  -moz-border-radius: 20px;
  -webkit-border-radius: 20px;
  -khtml-border-radius: 20px;
  border-radius: 20px;
  background: ${({ alert }) => HandleColor(alert)};
  padding: 12px 20px;
  margin-bottom: 2%;
  //opacity: 0.3;
  pointer-events: ${({ dismiss }) => (dismiss ? "auto" : "none")};
  cursor: ${({ dismiss }) => (dismiss ? "pointer" : "none")};
`;

export const Element = styled.ul`
  //border: 1px solid red;
`;

export const Icon = styled.li`
  position: absolute;
  float: none;
  clear: both;
  top: 50%;
  //left: 5%;
  left: 3px;
  width: 30px;
  display: inline-block;

  margin-top: -15px;
  margin-left: -15px;
  color: ${({ theme }) => theme.alert.icon};
  font-size: 22px;
  line-height: 30px;
  font-family: "${({ theme }) => theme.font.icon}";
  font-weight: 700;
  text-align: center;
  &::before {
    width: 100%;
    height: 100%;
    content: ${({ icon }) => '"\\' + icon + '"'};
  }
`;

export const Content = styled.li`
  // Toggle when an icon is set!
  margin-left: ${({ icon }) => (icon ? "23px" : "0")};
  //border: 1px solid yellow;
`;

export const Title = styled.h6`
  width: 100%;
  font-weight: 700;
  line-height: 1.2;
  color: ${({ theme }) => theme.alert.text.title};
`;
export const Description = styled.div`
  width: 100%;
  font-size: 9em;
  font-weight: 500;
  color: ${({ theme }) => theme.alert.text.subtitle};
  margin-top: 4px;
  line-height: 1.3;
`;
