import { createSlice } from "@reduxjs/toolkit";
import { partnerApiStart } from "../middleware/api";

function resolveLocation(object, path) {
  if (path) {
    return path.reduce(
      (previous, current) => previous && previous[current],
      object
    );
  } else {
    return null;
  }
}

const slice = createSlice({
  name: "UNI/Body",
  initialState: {
    loading: false,
    showGoHome: false,
    home: {
      curated: {
        get: false,
        list: [],
      },
    },
    search: {
      list: [],
      // Set to true when backend doesn't return any results
      empty: false,
      listFiltered: [],
      availableStock: false,
      supplierStock: {
        code: [],
        list: [],
        loading: false,
        updated: true,
      },
      //showFilter: false,
      filter: {
        stock: {
          name: "Само на залиха",
          match: {
            location: {
              value: ["q", "p"],
              name: null,
            },
            default: {
              name: "Нема",
              order: 1,
            },
            list: [
              {
                index: "T",
                name: "Има",
                order: 0,
                selected: true,
                disabled: true,
              },
            ],
          },
          optional: false,
          hidden: true,
          list: [],
        },
        brand: {
          name: "Бренд",
          match: {
            location: {
              value: ["b", "s"],
              name: ["b", "f"],
            },
            default: {
              name: null,
              order: null,
            },
            list: [
              { index: "GY", name: null, order: 1 },
              { index: "DU", name: null, order: 2 },
              { index: "FU", name: null, order: 3 },
              { index: "PI", name: null, order: 4 },
              { index: "MI", name: null, order: 5 },
              { index: "SA", name: null, order: 6 },
              { index: "DE", name: null, order: 7 },
              { index: "DI", name: null, order: 8 },
            ],
          },
          optional: false,
          hidden: false,
          list: [],
        },
        season: {
          name: "Сезона",
          match: {
            location: {
              value: ["s"],
              name: null,
            },
            default: {
              name: "Друго",
              order: 4,
            },
            list: [
              { index: "A", name: "Сите сезони", order: 2 },
              { index: "S", name: "Летни", order: 3 },
              { index: "W", name: "Зимски", order: 1 },
            ],
          },
          optional: false,
          hidden: false,
          list: [],
        },
        runflat: {
          name: "Run-flat",
          match: {
            location: {
              value: ["u", "p"],
              name: null,
            },
            default: {
              name: "Нема",
              order: 2,
            },
            list: [{ index: "T", name: "Има", order: 1 }],
          },
          optional: true,
          hidden: false,
          list: [],
        },
        fringe: {
          name: "Заштитен Венец",
          match: {
            location: {
              value: ["f", "p"],
              name: null,
            },
            default: {
              name: "Нема",
              order: 2,
            },
            list: [{ index: "T", name: "Има", order: 1 }],
          },
          optional: true,
          hidden: false,
          list: [],
        },
        noice: {
          name: "Редуктор на Бучава",
          match: {
            location: {
              value: ["n", "p"],
              name: null,
            },
            default: {
              name: "Нема",
              order: 2,
            },
            list: [{ index: "T", name: "Има", order: 1 }],
          },
          optional: true,
          hidden: false,
          list: [],
        },

        axel: {
          name: "Осовина",
          match: {
            location: {
              value: ["a"],
              name: null,
            },
            default: {
              name: "Друго",
              order: 5,
            },
            list: [
              { index: "A", name: "Универзална", order: 4 },
              { index: "S", name: "Предна", order: 1 },
              { index: "D", name: "Погонска", order: 2 },
              { index: "T", name: "Приколка", order: 3 },
            ],
          },
          optional: true,
          hidden: false,
          list: [],
        },

        position: {
          name: "Позиција",
          match: {
            location: {
              value: ["p"],
              name: null,
            },
            default: {
              name: "Друго",
              order: 12,
            },
            list: [
              { index: "FL", name: "Предна лева", order: 1 },
              { index: "FR", name: "Предна десна", order: 2 },
              { index: "FX", name: "Предна", order: 3 },
              { index: "ML", name: "Средна лева", order: 4 },
              { index: "MR", name: "Средна десна", order: 5 },
              { index: "MX", name: "Средна", order: 6 },
              { index: "RL", name: "Задна лева", order: 7 },
              { index: "RR", name: "Задна десна", order: 8 },
              { index: "RX", name: "Задна", order: 9 },
              { index: "XL", name: "Лева", order: 10 },
              { index: "XR", name: "Десна", order: 11 },
            ],
          },
          optional: true,
          hidden: false,
          list: [],
        },
      },
    },
  },
  reducers: {
    loaderStart: (body, action) => {
      body.loading = true;
    },
    loaderStop: (body, action) => {
      body.loading = false;
    },

    searchStarted: (body, action) => {
      // Show loader
      body.loading = true;
      // Reset lists
      body.search.listFiltered = [];

      // Reset empty reponse
      body.search.empty = false;

      // Reset supplier stock
      body.search.supplierStock.code = [];
      body.search.supplierStock.list = [];

      // Reset filters
      const filterKeys = Object.keys(body.search.filter);
      filterKeys.forEach((k) => {
        body.search.filter[k].list = [];
      });
    },

    searchStartedURL: (body, action) => {
      // Show loader
      body.loading = true;
      // Reset lists
      body.search.listFiltered = [];

      // Reset empty reponse
      body.search.empty = false;

      body.searchViaURL = true;

      // Reset supplier stock
      body.search.supplierStock.code = [];
      body.search.supplierStock.list = [];

      // Reset filters
      const filterKeys = Object.keys(body.search.filter);
      filterKeys.forEach((k) => {
        body.search.filter[k].list = [];
      });
    },

    /*
     * Receives the search response object
     * Sets the supplier codes
     * Maps the filter options to state
     * Checks if there is only one option in a each filter and selects it
     */
    searchRecieved: (body, action) => {
      // Assign data
      body.search.list = action.data.l;
      body.search.supplierStock.code = action.data.c;
      // Hide loader
      body.loading = false;

      // Mark response as empty
      if (action.data.l.length <= 0) {
        body.search.empty = true;
      } else {
        // Get the filter keys
        const filterKeys = Object.keys(body.search.filter);

        /*
         * Mapping the filter options
         */
        action.data.l.forEach((item) => {
          filterKeys.forEach((matchKey) => {
            // Get the value location
            var itemValue = resolveLocation(
              item,
              body.search.filter[matchKey].match.location.value
            );
            // Get the name location
            var itemName = resolveLocation(
              item,
              body.search.filter[matchKey].match.location.name
            );

            // Get the match index
            const matchIndex = body.search.filter[matchKey].match.list
              .map((e) => e.index)
              .indexOf(itemValue);

            // Check if match order index exists
            // Check if match default order exists
            // If not calculate it
            var itemOrder = 0;
            if (
              matchIndex >= 0 &&
              body.search.filter[matchKey].match.list[matchIndex].order
            ) {
              itemOrder =
                body.search.filter[matchKey].match.list[matchIndex].order;
            } else {
              // Check if a default order is set
              if (body.search.filter[matchKey].match.default.order) {
                itemOrder = body.search.filter[matchKey].match.default.order;
              } else {
                // If not then calculate it
                itemOrder = body.search.filter[matchKey].list.length + 1;
              }
            }

            // Check if match name exists
            // Check if match default name exists
            // If not fallback to item value
            if (
              matchIndex >= 0 &&
              body.search.filter[matchKey].match.list[matchIndex].name
            ) {
              itemName =
                body.search.filter[matchKey].match.list[matchIndex].name;
            } else {
              // Check if a default name is set
              if (body.search.filter[matchKey].match.default.name) {
                itemName = body.search.filter[matchKey].match.default.name;
              }
            }

            // Get item auto select state
            var itemSelected = false;
            if (
              matchIndex >= 0 &&
              body.search.filter[matchKey].match.list[matchIndex].selected ===
                true
            ) {
              itemSelected = true;
            }

            // Get item auto disable state
            var itemDisabled = false;
            if (
              matchIndex >= 0 &&
              body.search.filter[matchKey].match.list[matchIndex].disabled ===
                true
            ) {
              itemDisabled = true;
            }

            // Check if filter option is preent in the filters list
            // If so, return the index
            const filterIndex = body.search.filter[matchKey].list
              .map((e) => e.match)
              .indexOf(itemValue);

            // The index was not found
            // Will insert new option to filters list
            // Otherwise will update the item count
            if (filterIndex >= 0) {
              // Update the count
              body.search.filter[matchKey].list[filterIndex].count++;
            } else {
              // Push to filter option array
              body.search.filter[matchKey].list.push({
                name: itemName,
                match: itemValue,
                count: 1,
                selected: itemSelected,
                disabled: itemDisabled,
                order: itemOrder,
              });
            }
          });
        });

        /*
         * If there is only 1 option item, mark it as selected and disabled
         */
        filterKeys.forEach((key) => {
          if (body.search.filter[key].list.length === 1) {
            body.search.filter[key].list[0].selected = true;
            body.search.filter[key].list[0].disabled = true;
          }
        });

        /*
         * Only return items in stock, unless there are none in stock
         */
        const itemsInStock = body.search.list.filter((i) => i.q.v > 0);
        if (itemsInStock.length > 0) {
          // Disable auto fetch from supplier because we have stock
          body.search.availableStock = true;
          // We have items in stock add them to the filtered list
          body.search.listFiltered = itemsInStock;
        } else {
          // Fetch the supplier stock automatically because we have nothing in stock
          body.search.availableStock = false;
          // Return the entire search.list
          body.search.listFiltered = body.search.list;
        }

        // End else if empty reasponse
      }
    },

    /*
     * Gets filter selection imput
     * Processes all filter selections
     * Returns an alternative filtered list
     * While keeping the original data intact
     */
    filterUpdated: (body, action) => {
      // Determine the index
      const indexed = body.search.filter[action.payload.type].list
        .map((e) => e.match)
        .indexOf(action.payload.match);

      // Toggle boolean
      body.search.filter[action.payload.type].list[indexed].selected =
        action.payload.checked;

      // Get the filter keys
      const filterKeys = Object.keys(body.search.filter);

      // Will accumulate all filter selections
      let filterOption = {};

      // Process all filter selections
      filterKeys.forEach((k, i) => {
        // Create object with empty array if it does not exist
        filterOption[k] = filterOption[k] || [];
        // Filter the selected options and add them
        filterOption[k].push(
          ...body.search.filter[k].list
            .filter((i) => i.selected)
            .map((f) => f.match)
        );
      });

      // Final filtering of all search data, and add it to a new filtered list
      body.search.listFiltered = body.search.list.filter(
        (i) =>
          (filterOption.stock.length === 0 ||
            filterOption.stock.includes(i.q.p)) &&
          (filterOption.season.length === 0 ||
            filterOption.season.includes(i.s)) &&
          (filterOption.brand.length === 0 ||
            filterOption.brand.includes(i.b.s)) &&
          (filterOption.runflat.length === 0 ||
            filterOption.runflat.includes(i.u.p)) &&
          (filterOption.fringe.length === 0 ||
            filterOption.fringe.includes(i.f.p)) &&
          (filterOption.noice.length === 0 ||
            filterOption.noice.includes(i.n.p)) &&
          (filterOption.axel.length === 0 || filterOption.axel.includes(i.a)) &&
          (filterOption.position.length === 0 ||
            filterOption.position.includes(i.p))
      );
    },

    supplierStockStarted: (body, action) => {
      // Turn the loader on
      body.search.supplierStock.loading = true;
      // Mark the request as not updated
      body.search.supplierStock.updated = false;
    },
    supplierStockRecieved: (body, action) => {
      // Assign data
      body.search.supplierStock.list = action.data;

      /*
       * Assign the supplier stock data to the search list items
       */
      const source = body.search.list;
      action.data.forEach((item) => {
        const index = source.map((item) => item.d.c).indexOf(item.c);
        // Make sure the index was found
        if (index >= 0) {
          source[index]["fs"] = item.q;
          source[index]["l"] = false;
        }
      });
      // Assign updated data
      body.search.list = source;

      /*
       * Assign to listFiltered
       */
      const sourceFiltered = body.search.listFiltered;
      action.data.forEach((item) => {
        const index = sourceFiltered.map((item) => item.d.c).indexOf(item.c);
        // Make sure the index was found
        if (index >= 0) {
          sourceFiltered[index]["fs"] = item.q;
          sourceFiltered[index]["l"] = false;
        }
      });
      // Assign updated data to listFiltered
      body.search.listFiltered = sourceFiltered;

      // Mark as tru to ensure it the same request wont be made again
      body.search.availableStock = true;

      // Turn off the loader
      body.search.supplierStock.loading = false;
      // Mark the request as updated
      body.search.supplierStock.updated = true;
    },

    // Shows or hides the home button
    goHomeStatus: (body, action) => {
      if (action.payload.show) {
        if (action.payload.show === true) {
          body.showGoHome = true;
        } else {
          body.showGoHome = false;
        }
      }

      // Reset aka return to the homepage
      if ("goHome" in action.payload && action.payload.goHome === true) {
        // Hide the go home button
        body.showGoHome = false;
        // Clear the body search list
        body.search.list = [];
        // Clear the empty response flag
        body.search.empty = false;

        // Temporarly cleaning every filter one by one
        for (const key in body.search.filter) {
          body.search.filter[key].list = [];
        }
      }
    },

    // Check if curated is present in local storage and update it if needed
    curatedCheck: (body, action) => {
      body.loading = true;

      if (global.config.home.curated in localStorage) {
        // Recover from local storage
        var curated = JSON.parse(
          localStorage.getItem(global.config.home.curated)
        );

        var difference = Math.abs(curated.t - new Date().getTime());

        var hoursDifference = Math.floor(difference / 1000 / 60 / 60);
        difference -= hoursDifference * 1000 * 60 * 60;

        // Check how many hours passed since it was last fetched
        if (hoursDifference <= 12) {
          // No need to query the server again
          body.home.curated.get = false;

          // Update the list
          body.home.curated.list = curated.d.sort(() => Math.random() - 0.5);

          // Hide the loader
          body.loading = false;

          // 1 oe more days have passed since the last time curated was updated
        } else {
          // Keep loading
          body.loading = true;

          // Get the latest curated list
          body.home.curated.get = true;
        }
      } else {
        // Keep loading
        body.loading = true;

        // Neet to get the latest curated list
        body.home.curated.get = true;
      }
    },

    // Fetch the curated data from backend
    curatedRecieved: (body, action) => {
      if (action.data) {
        // Update the list
        body.home.curated.list = action.data.sort(() => Math.random() - 0.5);

        // Set token in local storage
        localStorage.setItem(
          global.config.home.curated,
          JSON.stringify({
            t: new Date().getTime(),
            d: action.data,
          })
        );

        // No need to query the server again
        body.home.curated.get = false;

        // Hide the loader
        body.loading = false;
      }
    },
  },
});

export const {
  filterUpdated,
  goHomeStatus,
  curatedCheck,
  loaderStart,
  loaderStop,
} = slice.actions;

export default slice.reducer;

export const getResult = (item) =>
  partnerApiStart({
    url: "/search/result",
    method: "post",
    authenthicateRequired: true,
    onStart: slice.actions.searchStarted.type,
    onSuccess: slice.actions.searchRecieved.type,
    //onError: slice.actions.searchFailed.type,
    data: { k: item.key },
  });

//export const getResult = (item) => console.log({ item });

export const getSupplierStock = (codes) =>
  partnerApiStart({
    url: "/stock/supplier",
    method: "post",
    authenthicateRequired: true,
    onStart: slice.actions.supplierStockStarted.type,
    onSuccess: slice.actions.supplierStockRecieved.type,
    //onError: slice.actions.searchFailed.type,
    data: { c: codes },
  });

export const getCurated = () =>
  partnerApiStart({
    url: "/stock/home",
    method: "get",
    authenthicateRequired: true,
    //onStart: ,
    onSuccess: slice.actions.curatedRecieved.type,
    //onError: ,
  });
