import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
  name: "UNI/Alert",
  initialState: {
    lastId: 4,
    list_: [
      {
        id: 1,
        type: 1,
        title: "No internet connection!",
        icon: "f5b4",
        expire: false,
        dismiss: false,
      },
      {
        id: 2,
        type: 3,
        title: "This is an alert!",
        description:
          "Nunc sollicitudin varius leo, quis mollis risus gravida nec.",
        icon: "f06a",
        expire: false,
        dismiss: false,
      },
      {
        id: 3,
        type: 2,
        expire: 10,
        title: "I will expire, wait for it...",
      },
      {
        id: 4,
        type: 1,
        dismiss: true,
        title: "You can dismiss me!",
        icon: "f0a4",
      },
    ],
    list: [],
  },
  reducers: {
    alertRemove: (alerts, action) => {
      alerts.list = alerts.list.filter((alert) => alert.id !== action.payload);
    },
    alertInfo: (alerts, action) => {
      alerts.lastId++;
      let id = alerts.lastId;
      alerts.list.push(
        setAlert(
          id,
          1,
          action.payload.title,
          action.payload.description,
          action.payload.icon,
          action.payload.expire,
          action.payload.dismiss
        )
      );
    },
    alertSuccess: (alerts, action) => {
      alerts.lastId++;
      let id = alerts.lastId;
      alerts.list.push(
        setAlert(
          id,
          2,
          action.payload.title,
          action.payload.description,
          action.payload.icon,
          action.payload.expire,
          action.payload.dismiss
        )
      );
    },
    alertError: (alerts, action) => {
      alerts.lastId++;
      let id = alerts.lastId;
      alerts.list.push(
        setAlert(
          id,
          3,
          action.payload.title,
          action.payload.description,
          action.payload.icon,
          action.payload.expire,
          action.payload.dismiss
        )
      );
    },
  },
});

export const { alertInfo, alertSuccess, alertError, alertRemove } =
  slice.actions;

export default slice.reducer;

const setAlert = (
  id,
  type,
  title = null,
  description = null,
  icon = false,
  expire = 5,
  dismiss = true
) => {
  return {
    id,
    type,
    title,
    description,
    icon,
    expire,
    dismiss,
  };
};

/*

Alert 
  - 1: Info (Navy Blue)
  - 2: Success (Green)
  - 3: Error (Red)

    - sad-tear
    - exclamation-triangle
    - check
    - check-circle
    - check-double
    - shield-check
    - thumbs-up

*/
