import React from "react";
import { connect } from "react-redux";
import {
  Container,
  FilterListContainer,
  FilterListScroll,
  FilterList,
  FooterContainer,
  FooterDisclaimer,
  FooterVersion,
  FooterBG,
  FooterCopytight,
  Pointer,
  PointerObject,
} from "./SidebarStyle";

import Header from "./Header/Header";
import Search from "./Search/Search";

import SidebarFilter from "./SidebarFilter/SidebarFilter";

import { filterUpdated } from "../../redux/store/body";

import { showPointer } from "../../redux/store/search";

import pointerSVG from "./pointer.svg";

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.containerRef = React.createRef();
    this.containerMutationObserver = null;
    this.containerResizeObserver = null;

    this.filterListRef = React.createRef();

    this.state = {
      top: false,
      filterContainerHeight: null,
    };
  }

  handleFilterChange = (props) => {
    this.props.dispatch(filterUpdated(props));
  };

  componentDidMount() {
    this.props.dispatch(showPointer(true));

    this.containerMutationObserver = new MutationObserver(
      this.handleContainerResize
    );
    this.containerMutationObserver.observe(this.containerRef.current, {
      childList: true,
      subtree: true,
    });

    this.containerResizeObserver = new ResizeObserver(
      this.handleContainerResize
    );
    this.containerResizeObserver.observe(this.containerRef.current);
  }

  componentWillUnmount() {
    if (this.containerMutationObserver) {
      this.containerMutationObserver.disconnect();
    }

    if (this.containerResizeObserver) {
      this.containerResizeObserver.disconnect();
    }
  }

  handleContainerResize = () => {
    if (
      this.filterListRef.current &&
      this.filterListRef.current.clientHeight <
        this.containerRef.current.clientHeight - 250
    ) {
      const value = this.filterListRef.current.clientHeight;
      if (this.state.filterContainerHeight !== value) {
        this.setState({
          filterContainerHeight: this.filterListRef.current.clientHeight,
        });
      }
    } else if (
      this.filterListRef.current &&
      this.filterListRef.current.clientHeight >=
        this.containerRef.current.clientHeight - 250
    ) {
      const value = this.containerRef.current.clientHeight / 2;
      if (this.state.filterContainerHeight !== value) {
        this.setState({
          filterContainerHeight: value,
        });
      }
    } else {
      if (this.state.filterContainerHeight !== null) {
        this.setState({
          filterContainerHeight: null,
        });
      }
    }
  };

  render() {
    const filtered = Object.keys(this.props.body_search_filter).filter((k) => {
      const f = this.props.body_search_filter[k];

      return (
        f.list.length > 0 &&
        ((!f.optional && !f.hidden) ||
          (f.list.length !== 1 && !f.list[0].disabled))
      );
    });

    return (
      <Container ref={this.containerRef}>
        <Header />
        <Search />

        {filtered.length && (
          <FilterListContainer
            containerHeight={this.state.filterContainerHeight}
          >
            <FilterListScroll>
              <FilterList ref={this.filterListRef}>
                {filtered.map((k) => (
                  <SidebarFilter
                    key={k}
                    type={k}
                    filter={this.props.body_search_filter[k]}
                    handleChange={this.handleFilterChange}
                  />
                ))}
              </FilterList>
            </FilterListScroll>
          </FilterListContainer>
        )}

        <Pointer show={this.props.showPointer}>
          <PointerObject
            type="image/svg+xml"
            data={pointerSVG}
            aria-label="pointer"
          />
        </Pointer>

        <FooterContainer>
          <FooterDisclaimer>
            Апликацијата е во фаза на развивање—можни се несогласувања на
            производите, количините или цените.
          </FooterDisclaimer>
          <FooterDisclaimer>
            Критики или проблеми пријавете на partner@uniroyal.mk или на 02 5
            300 700
          </FooterDisclaimer>
          <FooterVersion>v{process.env.REACT_APP_VERSION}</FooterVersion>
          <FooterCopytight>
            ©{new Date().getFullYear()} УНИ-РОЈАЛ КО. ДООЕЛ
          </FooterCopytight>
        </FooterContainer>
        <FooterBG />
      </Container>
    );
  }
}

export default connect((state) => ({
  showPointer: state.search.showPointer,
  body_search_filter: state.body.search.filter,
}))(Sidebar);
