import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { alertRemove } from "../redux/store/alert";

const Expire = (props) => {
  const [visible, setVisible] = useState(true);
  const dispatch = useDispatch();

  var delay = 5000;

  if (props.delay) {
    delay = props.delay * 1000;
  }

  useEffect(() => {
    let mounted = true;
    setTimeout(() => {
      if (mounted) {
        if (props.type === "alert") {
          dispatch(alertRemove(props.children.props.id));
        } else {
          setVisible(false);
        }
      }
    }, delay);
    return () => {
      mounted = false;
    };
  }, [props.children.props.id, dispatch, props.type, delay]);

  return visible ? props.children : null;
};

export default Expire;
