import React from "react";
import { connect } from "react-redux";

import {
  Container,
  AlignmentList,
  AlignmentListItemLeft,
  AlignmentListItemRight,
  GoBack,
  FadeLeft,
} from "./HeaderStyle";

import HeaderUser from "./HeaderUser/HeaderUser";
import HeaderEntity from "./HeaderEntity/HeaderEntity";
import { clearQuery, showPointer } from "../../redux/store/search";
import { goHomeStatus } from "../../redux/store/body";

class Header extends React.Component {
  handleGoHomeButton = () => {
    this.props.dispatch(clearQuery());
    this.props.dispatch(goHomeStatus({ goHome: true }));
    this.props.dispatch(showPointer(true));
  };

  render() {
    return (
      <Container>
        <AlignmentList>
          <AlignmentListItemLeft>
            <GoBack
              onClick={this.handleGoHomeButton}
              show={this.props.bodyShowGoHome}
            />
          </AlignmentListItemLeft>
          <AlignmentListItemRight>
            <HeaderUser />
            <HeaderEntity />
          </AlignmentListItemRight>
        </AlignmentList>
        <FadeLeft />
      </Container>
    );
  }
}

export default connect(
  ({ body }) => ({
    bodyShowGoHome: body.showGoHome,
  }),
  null
)(Header);
