module.exports = global.config = {
  api: {
    partner: process.env.REACT_APP_BACKEND_API,
  },

  captcha: {
    key: "6Lcj-W4dAAAAAPuzIaNo3sc7tEiLBtn0TPQBEZho",
  },

  storage: {
    /*
     * UNI. PARTNER SESSION
     * The JWT token used for authentication of each request
     */
    session: "upss",

    /*
     * UNI. PARTNER USER INFO
     * The user's name and possibly other personal insensitive info in the future
     */
    info: "upui",
  },

  search: {
    /*
     * UNI. PARTNER Search Resent Tire Size
     * JSON Object
     */
    recent: "upsrts",
  },

  home: {
    curated: "uphctd",
  },
};
