import styled from "styled-components";

export const RecentSearchList = styled.ul`
  width: 100%;
`;

export const RecentSearchItem = styled.li`
  width: 100%;
  margin-top: 30px;

  &:first-child {
    margin-top: 0;
  }
`;

export const RecentSearchItemTitle = styled.h3`
  width: 100%;
  margin-left: 3px;
  margin-bottom: 3px;
  //text-transform: uppercase;
`;

export const List = styled.ul`
  width: 100%;
  max-height: 100%;

  margin-top: 15px;

  display: flex;
  flex-wrap: wrap;
  flex-direction: row;

  justify-content: space-between;
  align-items: flex-start;
  align-content: space-between;

  //flex-flow: column wrap;
  //flex-flow: wrap;
`;

export const ChipsList = styled.ul`
  width: 100%;
  margin-top: 5px;
`;

export const ChipsItem = styled.li`
  margin: 10px 10px 0 0;
`;

export const ChipsButton = styled.button`
  -webkit-transition: background-color 0.15s;
  -moz-transition: background-color 0.15s;
  -o-transition: background-color 0.15s;
  transition: background-color 0.15s;

  background-color: ${({ theme }) =>
    theme.body.home.recent.chip.background.default};
  padding: 12px 20px;

  -moz-border-radius: 100px;
  -webkit-border-radius: 100px;
  -khtml-border-radius: 100px;
  border-radius: 100px;
  color: ${({ theme }) => theme.body.home.recent.chip.text};
  font-size: 11em;
  font-weight: 700;

  text-transform: uppercase;
  /*
  &:first-line {
    text-transform: uppercase;
  }*/
  &:hover {
    background-color: ${({ theme }) =>
      theme.body.home.recent.chip.background.hover};
  }
`;
