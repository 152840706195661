import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import { combineReducers } from "redux";

import authenticator from "./authenticator";

import alert from "./alert";
import search from "./search";
import body from "./body";

import api from "../middleware/api";

const reducer = combineReducers({
  authenticator,
  alert,
  search,
  body,
});
const store = configureStore({
  reducer,
  middleware: [...getDefaultMiddleware(), api],
});

export default store;
