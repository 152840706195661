import styled from "styled-components";

export const Container = styled.div`
  @media (max-width: 760px) {
    position: absolute;
  }

  position: fixed;
  float: none;
  clear: both;

  width: ${({ theme }) => 100 - theme.sidebar.width.percent}%;
  width: -webkit-calc(100% - ${({ theme }) => theme.sidebar.width.pixel}px);
  width: -moz-calc(100% - ${({ theme }) => theme.sidebar.width.pixel}px);
  width: calc(100% - ${({ theme }) => theme.sidebar.width.pixel}px);

  right: 0;
  top: 0;

  height: ${({ theme }) => theme.header.height.percent}%;
  height: -webkit-calc(${({ theme }) => theme.header.height.pixel}px);
  height: -moz-calc(${({ theme }) => theme.header.height.pixel}px);
  height: calc(${({ theme }) => theme.header.height.pixel}px);

  background: ${({ theme }) => theme.header.background};
  white-space: nowrap;
  z-index: 150;
  &:before {
    content: "";
    display: inline-block;
    height: 100%;
    //margin-right: -0.25em;
    vertical-align: middle;
  }

  min-width: 400px;
`;

export const AlignmentList = styled.ul`
  -webkit-transition: min-width 0.25s, margin 0.25s;
  -moz-transition: min-width 0.25s, margin 0.25s;
  -o-transition: min-width 0.25s, margin 0.25s;
  transition: min-width 0.25s, margin 0.25s;

  float: none;
  min-width: 94%;
  max-width: 94%;
  margin: 0 3%;
  display: inline-block;
  vertical-align: middle;

  @media (max-width: 1500px) {
    min-width: 96%;
    max-width: 96%;
    margin: 0 2%;
  }
`;

export const AlignmentListItemLeft = styled.li`
  float: left;
  margin-right: 10px;
`;

export const AlignmentListItemRight = styled.li`
  float: right;
  margin-left: 10px;
`;

export const GoBack = styled.button`
  -webkit-transition: margin 0.35s, padding 0.25s, color 0.15s, opacity 0.3s;
  -moz-transition: margin 0.35s, padding 0.25s, color 0.15s, opacity 0.3s;
  -o-transition: margin 0.35s, padding 0.25s, color 0.15s, opacity 0.3s;
  transition: margin 0.35s, padding 0.25s, color 0.15s, opacity 0.3s;
  transition-timing-function: ease-in-out;

  position: absolute;
  top: 50%;
  left: 0;
  //right: -800px;
  margin-top: -6.5px;
  margin-left: ${({ show }) => (show ? "-10px" : "-200px")};
  pointer-events: ${({ show }) => (show ? "auto" : "none")};
  opacity: ${({ show }) => (show ? 1 : 0)};
  color: ${({ theme }) => theme.body.header.user.button.background.default};
  padding: 7px 20px 7px 10px;
  font-family: "${({ theme }) => theme.font.icon}";
  font-weight: 700;
  z-index: 100;

  //background: pink;

  &::before {
    padding: 0;
    margin: 0;
    font-size: 38px;
    line-height: 38px;
    content: "\f053";
  }
  &:hover {
    padding: 7px 24px 7px 8px;
    color: ${({ theme }) => theme.body.header.user.button.background.hover};
  }
  &:active {
    padding: 7px 24px 7px 4px;
  }
`;

export const GoBack1 = styled.button`
  -webkit-transition: margin 0.35s, background-color 0.15s, opacity 0.2s;
  -moz-transition: margin 0.35s, background-color 0.15s, opacity 0.2s;
  -o-transition: margin 0.35s, background-color 0.15s, opacity 0.2s;
  transition: margin 0.35s, background-color 0.15s, opacity 0.2s;

  float: left;
  margin-left: ${({ show }) => (show ? 0 : "-200px")};

  opacity: ${({ show }) => (show ? 1 : 0)};

  background-color: ${({ theme }) =>
    theme.body.header.user.button.background.default};
  padding: 12px 20px;
  -moz-border-radius: 100px;
  -webkit-border-radius: 100px;
  -khtml-border-radius: 100px;
  border-radius: 100px;
  color: ${({ theme }) => theme.body.header.user.button.text};
  font-size: 11pt;
  font-weight: 700;

  z-index: 100;

  /*
  &::after {
    -webkit-transition: all 0.2s;
    -moz-transition: all 0.2s;
    -o-transition: all 0.2s;
    transition: all 0.2s;

    content: "";

    -moz-border-radius: 100px;
    -webkit-border-radius: 100px;
    -khtml-border-radius: 100px;
    border-radius: 100px;

    position: absolute;
    top: 50%;
    left: 50%;
    width: 0%;
    height: 0%;

    background-color: ${({ theme }) =>
    theme.body.header.user.button.background.default};

    opacity: 0.5;

    z-index: 90;
  }
  */

  &:hover {
    background-color: ${({ theme }) =>
      theme.body.header.user.button.background.hover};

    /*
    &::after {
      background-color: ${({ theme }) =>
      theme.body.header.user.button.background.hover};

      top: -7.5%;
      left: -5%;
      width: 110%;
      height: 115%;
    }
    */
  }

  /*
  &:active {
    background-color: ${({ theme }) =>
    theme.body.header.user.button.background.default};
  */
`;

export const FadeLeft = styled.div`
  -webkit-transition: min-width 0.25s;
  -moz-transition: min-width 0.25s;
  -o-transition: min-width 0.25s;
  transition: min-width 0.25s;

  position: absolute;
  float: none;
  clear: both;
  top: 0;
  left: 0;

  pointer-events: none;

  min-width: 3%;
  max-width: 3%;
  height: 100%;

  @media (max-width: 1500px) {
    min-width: 2%;
    max-width: 2%;
  }

  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&1+0,0+100 */
  background: -moz-linear-gradient(
    left,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0) 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    left,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0) 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0) 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#00ffffff',GradientType=1 ); /* IE6-9 */

  z-index: 105;
`;
