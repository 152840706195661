import React from "react";
import { connect } from "react-redux";

import {
  RecentSearchList,
  RecentSearchItem,
  RecentSearchItemTitle,
  ChipsList,
  ChipsItem,
  ChipsButton,
  List,
} from "./HomeStyle";

import { suggestionClicked } from "../../../../redux/store/search";
import { getResult, goHomeStatus } from "../../../../redux/store/body";
import { curatedCheck } from "../../../../redux/store/body";

import SearchItem from "../Search/SearchItem/SearchItem";

class Home extends React.Component {
  handleClick = (item) => {
    this.props.dispatch(suggestionClicked({ k: item.k, n: item.n }));
    this.props.dispatch(getResult({ key: item.k }));
  };

  componentDidMount() {
    // If the go back home button is shown, hide it
    if (this.props.bodyShowGoHome) {
      this.props.dispatch(goHomeStatus({ show: false }));
    }

    // Perform curated check
    this.props.dispatch(curatedCheck());
  }

  getSearchPopular = () => {
    const popular = [
      { n: "175/65 14", k: 10173 },
      { n: "195/65 15", k: 10079 },
      { n: "205/55 16", k: 10056 },
      { n: "225/45 17", k: 10060 },
      { n: "225/55 17", k: 10169 },
      { n: "225/45 18", k: 10201 },
    ];
    return (
      <RecentSearchItem>
        <RecentSearchItemTitle>Најбарани</RecentSearchItemTitle>
        <ChipsList>
          {popular.map((item) => (
            <ChipsItem key={"r" + item.k + item.t}>
              <ChipsButton
                onClick={() => this.handleClick({ k: item.k, n: item.n })}
              >
                {item.n}
              </ChipsButton>
            </ChipsItem>
          ))}
        </ChipsList>
      </RecentSearchItem>
    );
  };

  getSearchRecent = () => {
    if (global.config.search.recent in localStorage) {
      var recent = JSON.parse(
        localStorage.getItem(global.config.search.recent)
      );
      if (recent.lenght <= 0) {
        return this.getSearchPopular();
      }
      return (
        <RecentSearchItem>
          <RecentSearchItemTitle>Последно пребарани</RecentSearchItemTitle>
          <ChipsList>
            {recent
              .filter((item) => item.k)
              .sort((x, y) => y.t - x.t)
              .slice(0, 10)
              .map((item) => (
                <ChipsItem key={"r" + item.k + item.t}>
                  <ChipsButton
                    onClick={() => this.handleClick({ k: item.k, n: item.n })}
                  >
                    {item.n}
                  </ChipsButton>
                </ChipsItem>
              ))}
          </ChipsList>
        </RecentSearchItem>
      );
    } else {
      return this.getSearchPopular();
    }
  };

  getCuratedItems = () => {
    return (
      <RecentSearchItem>
        <RecentSearchItemTitle>Препорачани</RecentSearchItemTitle>
        <List>
          {this.props.bodyHomeCuratedList.map((item) => (
            <SearchItem key={item.k + item.d.c} item={item} />
          ))}
        </List>
      </RecentSearchItem>
    );
  };

  render() {
    return (
      <React.Fragment>
        <RecentSearchList>{this.getSearchRecent()}</RecentSearchList>
        {this.getCuratedItems()}
      </React.Fragment>
    );
  }
}

export default connect(({ body }) => ({
  bodySearchList: body.search.list,
  bodyLoading: body.loading,
  bodyShowGoHome: body.showGoHome,
  bodyHomeCuratedGet: body.home.curated.get,
  bodyHomeCuratedList: body.home.curated.list,
}))(Home);

/*
175/65R14
195/65R15
205/55R16
225/45R17
225/55R17
225/45R18
    return recent
        .sort((x, y) => y.t - x.t)
        .slice(0, 10)
        .map((item) => (
          <ChipsItem key={"r" + item.k + item.t}>
            <ChipsButton
              onClick={() => this.handleClick({ k: item.k, n: item.n })}
            >
              {item.n}
            </ChipsButton>
          </ChipsItem>
        ));
return popular.map((item) => (
        <ChipsItem key={"r" + item.k + item.t}>
          <ChipsButton
            onClick={() => this.handleClick({ k: item.k, n: item.n })}
          >
            {item.n}
          </ChipsButton>
        </ChipsItem>
      ));
*/
