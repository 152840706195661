import styled from "styled-components";

export const Container = styled.div`
  -webkit-transition: min-width 0.25s, margin 0.25s;
  -moz-transition: min-width 0.25s, margin 0.25s;
  -o-transition: min-width 0.25s, margin 0.25s;
  transition: min-width 0.25s, margin 0.25s;

  @media (max-width: 1500px) {
    margin-left: 4%;
    margin-right: 4%;
    min-width: 92%;
    max-width: 92%;
    height: auto;
  }

  @media (min-width: 2000px) {
    width: 1500px;
    min-width: 1500px;
    max-width: 1500px;

    margin-left: auto;
    margin-right: auto;
  }

  margin-left: 6%;
  margin-right: 6%;
  min-width: 88%;
  max-width: 88%;
  height: auto;

  //margin: 0 6% 120px;

  padding: 0;
  padding-top: ${({ theme }) => theme.header.height.percent / 2}%;
  padding-top: -webkit-calc(${({ theme }) => theme.header.height.pixel / 2}px);
  padding-top: -moz-calc(${({ theme }) => theme.header.height.pixel / 2}px);
  padding-top: calc(${({ theme }) => theme.header.height.pixel / 2}px);
`;

export const LoaderContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100px;
  height: 300px;
  margin: -150px 0 0 -50px;
`;

export const LoaderSVG = styled.svg`
  -webkit-animation: rotate 2s linear infinite;
  -moz-animation: rotate 2s linear infinite;
  -ms-animation: rotate 2s linear infinite;
  -o-animation: rotate 2s linear infinite;
  animation: rotate 2s linear infinite;

  width: 100%;
  height: 100%;
`;

export const LoaderCircle = styled.circle`
  -webkit-animation: spinner 1.5s ease-in-out infinite;
  -moz-animation: spinner 1.5s ease-in-out infinite;
  -ms-animation: spinner 1.5s ease-in-out infinite;
  -o-animation: spinner 1.5s ease-in-out infinite;
  animation: spinner 1.5s ease-in-out infinite;

  stroke: ${({ theme }) => theme.body.loader};
  stroke-linecap: round;
  stroke-dasharray: 90, 150;
  stroke-dashoffset: 0;
`;

export const BottomSpacer = styled.div`
  width: 100%;
  float: left;

  height: ${({ theme }) => theme.header.height.percent * 2}%;
  height: -webkit-calc(${({ theme }) => theme.header.height.pixel * 2}px);
  height: -moz-calc(${({ theme }) => theme.header.height.pixel * 2}px);
  height: calc(${({ theme }) => theme.header.height.pixel * 2}px);
  position: relative;
`;
