import React from "react";
import { connect } from "react-redux";

import { revoke } from "../../../redux/store/authenticator";

import { User } from "./HeaderUserStyle";

class HeaderUser extends React.Component {
  handleClick = () => {
    this.props.dispatch(revoke());
  };

  render() {
    return <User onClick={this.handleClick}>Одјави се</User>;
  }
}

export default connect()(HeaderUser);
