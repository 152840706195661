import styled from "styled-components";

export const Container = styled.div`
  position: absolute;
  float: none;
  clear: both;
  top: ${({ theme }) => theme.header.height.percent}%;
  top: -webkit-calc(${({ theme }) => theme.header.height.pixel}px);
  top: -moz-calc(${({ theme }) => theme.header.height.pixel}px);
  top: calc(${({ theme }) => theme.header.height.pixel}px);
  right: 0;
  z-index: 900;
  width: 30%;
`;

export const List = styled.ul`
  float: right;
  margin-top: 2%;
  margin-right: 3%;
  width: 100%;
`;
