import { createSlice } from "@reduxjs/toolkit";
import { partnerApiStart } from "../middleware/api";

const slice = createSlice({
  name: "UNI/Search",
  initialState: {
    // Search Input field
    query: "",
    // Used for auto-complete
    appended: 0,
    // Display loader
    loading: false,

    // Wiggle the magnifier glass
    wiggle: false,

    showPointer: false,

    // Suggestions list
    suggestions: {
      show: false,
      list: null,
    },
    // Main container
    body: {
      search: null,
    },
  },
  reducers: {
    showPointer: (search, action) => {
      search.showPointer = action.payload;
    },

    searchRequested: (search, action) => {
      //console.log("requested - ", action);
      search.selected = false;
      search.loading = true;
      search.query = action.pass;
      search.wiggle = false;
      //search.showPointer = true;
    },
    searchRecieved: (search, action) => {
      search.wiggle = false;
      search.selected = false;
      search.loading = false;

      if (action.data["m"]) {
        //console.log("search.query", search.query);
        //console.log('action.data["m"]', action.data["m"]);
        //const common = action.data.c.replace(/\D/g, "");
        //console.log("common", common);

        /*
        Auto fill in the search box is off

        // Check if the currect query is not equal to the common
        if (search.query !== common) {
          // Check if there were appended strings during auto-complete
          if (search.appended > 0) {
            // Remove any additional strings added
            search.query = search.query.slice(0, -search.appended);
            search.appended = 0;
          } else {
            // Calculate the difference, and store the value
            search.appended = Math.abs(search.query.length - common.length);
            // Change the query
            search.query = common;
          }
        } else {
          search.appended = 0;
        }
      */

        search.suggestions.list = action.data;
        search.suggestions.show = true;
        search.showPointer = false;
      } else {
        if (action.status === 206) {
          search.wiggle = false;
          search.suggestions.list = null;
          //search.showPointer = true;
        } else {
          search.wiggle = true;
          search.suggestions.list = null;
          //search.showPointer = true;
        }
      }
    },
    searchError: (search, action) => {
      search.loading = false;
      console.log(action);
    },
    searchEmpty: (search) => {
      search.selected = false;
      search.loading = false;
      search.query = "";
      search.suggestions.list = null;
      search.suggestions.show = false;
    },
    searchWiggle: (search, action) => {
      search.wiggle = action.payload;
    },
    suggestionClicked: (search, action) => {
      // Hide loader
      search.loading = false;
      // Hide the suggestions box
      search.suggestions.show = false;
      // Set the query to the full size
      search.query = action.payload.n.replace(/\D/g, "");
      // Reset the suggestions list
      search.suggestions.list = null;

      search.showPointer = false;

      /*
       * Process the search history in local storage
       * Check if it already exists
       */
      if (global.config.search.recent in localStorage) {
        // Fetch the search history from local storage
        var recent = JSON.parse(
          localStorage.getItem(global.config.search.recent)
        );
        // Find the index of a tire seize by it's ID aka key
        const item = recent.findIndex((i) => i.k === action.payload.k);

        /*
         * Item exists, index found
         * Update name, timestamp and count
         */
        if (item >= 0) {
          recent[item].n = action.payload.n;
          recent[item].t = new Date().getTime();
        } else {
          // Item doesn't exist, append new
          recent.push({
            n: action.payload.n,
            k: action.payload.k,
            t: new Date().getTime(),
          });
        }

        // Update local storage
        // Sort by timestamp
        // Get the first 15
        localStorage.setItem(
          global.config.search.recent,
          JSON.stringify(recent.sort((x, y) => y.t - x.t).slice(0, 15))
        );
      } else {
        /*
         * No recent searches were found
         * Setting up a new object
         */
        localStorage.setItem(
          global.config.search.recent,
          JSON.stringify([
            {
              n: action.payload.n,
              k: action.payload.k,
              t: new Date().getTime(),
            },
          ])
        );
      }
    },
    // Show the suggestions box
    suggestionShow: (search) => {
      search.suggestions.show = true;
      search.showPointer = false;
    },
    // Hide the suggestions box
    suggestionHide: (search) => {
      search.suggestions.show = false;
      search.showPointer = true;
    },

    // Clear the search query
    clearQuery: (search) => {
      search.query = "";
    },
  },
});

export const {
  suggestionClicked,
  suggestionShow,
  suggestionHide,
  searchEmpty,
  searchWiggle,
  clearQuery,
  showPointer,
} = slice.actions;

export default slice.reducer;

export const handleSearch = (query) =>
  partnerApiStart({
    url: "/search",
    method: "post",
    authenthicateRequired: true,
    onStart: slice.actions.searchRequested.type,
    onSuccess: slice.actions.searchRecieved.type,
    onError: slice.actions.searchError.type,
    data: { q: query },
    pass: query,
  });
