import styled from "styled-components";

const pendantPaddingY = "8px";
const pendantPaddingX = "12px";

export const Item = styled.li`
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  -khtml-border-radius: 10px;
  border-radius: 10px;
  background: ${({ theme }) => theme.body.search.list.item.background};
  overflow: none;

  display: block;
  width: 100%;
  margin-top: 8px;
  &:first-child {
    margin-top: 0;
  }

  @media (min-width: 1100px) {
    //display: flex;
    display: inline-flex;

    width: 49.5%;
    margin: 0;
    margin-bottom: 1%;

    flex: 0 0 auto;
    align-items: stretch;

    //align-self: flex-start;
    align-self: stretch; // Should switch to mosaic - only temporary
  }
`;

export const ItemListLeft = styled.ul`
  width: 60%;
  padding-top: 5px;
  //background: yellow;
`;

export const ListLeftItemTitle = styled.li`
  width: 97%;
  font-size: 10em;
  margin: 12px 0 0 5%;
  font-weight: 700;
  line-height: 1.1;
`;

export const ListLeftItemBrand = styled.li`
  width: 97%;
  font-size: 10em;
  margin: 6px 0 5px 5%;
  font-weight: 700;
`;

export const ListLeftItemCode = styled.li`
  width: 95%;
  font-size: 8em;
  margin: 5px 0 12px 5%;
  font-weight: 500;
  -webkit-touch-callout: auto;
  -webkit-user-select: auto;
  -khtml-user-select: auto;
  -moz-user-select: auto;
  -ms-user-select: auto;
  user-select: auto;
  pointer-events: auto;
`;

export const ListLeftItemBarcode = styled.li`
  width: 95%;
  font-size: 8em;
  margin: 12px 0 0 5%;
  font-weight: 500;
  -webkit-touch-callout: auto;
  -webkit-user-select: auto;
  -khtml-user-select: auto;
  -moz-user-select: auto;
  -ms-user-select: auto;
  user-select: auto;
  pointer-events: auto;
`;

export const PendantList = styled.ul`
  margin-left: 3%;
  width: 100%;
`;

export const PendantItem = styled.li`
  font-size: 9em;
  //line-height: 2.1em;
  padding: ${pendantPaddingY} ${pendantPaddingX};
  //padding-top: 0.5px;
  margin: 5px 2% 0 0;
  font-weight: 700;
  -moz-border-radius: 100px;
  -webkit-border-radius: 100px;
  -khtml-border-radius: 100px;
  border-radius: 100px;
  background: ${({ theme }) => theme.body.search.list.item.pendant.background};
  color: ${({ theme }) => theme.body.search.list.item.pendant.text};
`;

export const ItemListRight = styled.ul`
  width: 40%;
`;

export const ListRightIcon = styled.li`
  float: right;
  font-size: 22px;
  line-height: 30px;
  font-family: "${({ theme }) => theme.font.icon}";
  font-weight: 700;
  text-align: right;
  margin: 5px 8% -4px 50%;
  pointer-events: auto;
  &::before {
    width: 100%;
    height: 100%;
    content: ${({ icon }) => '"\\' + icon + '"'};
  }
`;

export const ListRightStock = styled.li`
  right: 0;
  float: right;

  margin: 12px 5% 12px 0;
`;

export const ListRightStockList = styled.ul`
  right: 0;
  float: right;
`;

export const ListRightStockItem = styled.li`
  -moz-border-radius: 100px;
  -webkit-border-radius: 100px;
  -khtml-border-radius: 100px;
  border-radius: 100px;

  right: 0;
  float: right;

  width: 100%;

  display: block;

  margin-bottom: 5px;
  &:last-child {
    margin-bottom: 0;
  }
`;

export const ListRightStockItemText = styled.div`
  -moz-border-radius: 100px;
  -webkit-border-radius: 100px;
  -khtml-border-radius: 100px;
  border-radius: 100px;

  right: 0;
  float: right;

  display: block;

  padding: ${pendantPaddingY} ${pendantPaddingX};

  background: ${({ theme }) =>
    theme.body.search.list.item.stock.available.background};

  font-size: 9em;
  font-weight: 700;
  color: ${({ theme }) => theme.body.search.list.item.stock.available.text};
`;

export const ListRightPrice = styled.li`
  width: 100%;
  font-size: 10em;
  float: right;
  margin: 0 8% 12px 0;
  font-weight: 700;
  text-align: right;
`;

export const SupplierStock = styled.ul`
  width: 100%;
  margin: 12px 0 12px;
`;
export const SupplierStockItem = styled.li`
  font-size: 9em;
  float: right;
  clear: both;
  margin: 0 5% 5px 0;
  padding: ${pendantPaddingY} ${pendantPaddingX};
  font-weight: 700;
  -moz-border-radius: 100px;
  -webkit-border-radius: 100px;
  -khtml-border-radius: 100px;
  border-radius: 100px;
  background: ${({ theme }) =>
    theme.body.search.list.item.stock.supplier.background};
  color: ${({ theme }) => theme.body.search.list.item.stock.supplier.text};
  &:last-child {
    margin-bottom: 0;
  }
`;

export const SupplierStockLoader = styled.li`
  float: right;
  margin: 8px 4% 0 50%;
  width: 40px;
  height: 40px;
  &:last-child {
    margin-bottom: 0;
  }
`;

export const LoaderSVG = styled.svg`
  -webkit-animation: rotate 2s linear infinite;
  -moz-animation: rotate 2s linear infinite;
  -ms-animation: rotate 2s linear infinite;
  -o-animation: rotate 2s linear infinite;
  animation: rotate 2s linear infinite;

  width: 100%;
  height: 100%;
`;

export const LoaderCircle = styled.circle`
  -webkit-animation: spinner 1.5s ease-in-out infinite;
  -moz-animation: spinner 1.5s ease-in-out infinite;
  -ms-animation: spinner 1.5s ease-in-out infinite;
  -o-animation: spinner 1.5s ease-in-out infinite;
  animation: spinner 1.5s ease-in-out infinite;

  stroke: ${({ theme }) => theme.body.loader};
  stroke-linecap: round;
  stroke-dasharray: 90, 150;
  stroke-dashoffset: 0;
`;

export const TireLabelRatingList = styled.ul`
  margin-left: 3%;
  width: 100%;
`;

export const TireLabelRatingText = styled.div`
  float: right;
  text-alight: left;
  //font-size: 22px;
  //line-height: 30px;
  font-weight: 700;
  //margin: 5px 8% -4px 50%;
  pointer-events: auto;
  color: inherit;
`;

const handleTireLabelRatingFuelBackground2020 = (rating, theme) => {
  switch (rating) {
    case "A":
      return "#009432";
    case "B":
      return "#a3cb38";
    case "C":
      return "#fed327";
    case "D":
      return "#fc9313";
    case "E":
      return "#e2001a";
    default:
      return theme.body.search.list.item.pendant.background;
  }
};

const handleTireLabelRatingFuelColor2020 = (rating, theme) => {
  switch (rating) {
    case "A":
      return "rgba(255,255,255,1)";
    case "B":
      return theme.body.search.list.item.pendant.text;
    case "C":
      return theme.body.search.list.item.pendant.text;
    case "D":
      return theme.body.search.list.item.pendant.text;
    case "E":
      return "rgba(255,255,255,1)";
    default:
      return theme.body.search.list.item.pendant.text;
  }
};

export const TireLabelRatingFuel2020 = styled.li`
  font-size: 9em;
  padding: ${pendantPaddingY} ${pendantPaddingX};
  padding-left: 35px;
  margin: 5px 2% 0 0;
  font-weight: 700;
  -moz-border-radius: 100px;
  -webkit-border-radius: 100px;
  -khtml-border-radius: 100px;
  border-radius: 100px;
  background: ${({ rating, theme }) =>
    handleTireLabelRatingFuelBackground2020(rating, theme)};
  color: ${({ rating, theme }) =>
    handleTireLabelRatingFuelColor2020(rating, theme)};
`;

const handleTireLabelRatingBackground2009 = (rating, theme) => {
  switch (rating) {
    case "A":
      return "#009432";
    case "B":
      return "#52b035";
    case "C":
      return "#a3cb38";
    case "D":
      return "#fed327";
    case "E":
      return "#ffa312";
    case "F":
      return "#f36215";
    case "G":
      return "#e2001a";
    default:
      return theme.body.search.list.item.pendant.background;
  }
};

const handleTireLabelRatingColor2009 = (rating, theme) => {
  switch (rating) {
    case "A":
      return "rgba(255,255,255,1)";
    case "B":
      return "rgba(255,255,255,1)";
    case "C":
      return theme.body.search.list.item.pendant.text;
    case "D":
      return theme.body.search.list.item.pendant.text;
    case "E":
      return theme.body.search.list.item.pendant.text;
    case "F":
      return "rgba(255,255,255,1)";
    case "G":
      return "rgba(255,255,255,1)";
    default:
      return theme.body.search.list.item.pendant.text;
  }
};

export const TireLabelRatingFuel2009 = styled.li`
  font-size: 9em;
  padding: ${pendantPaddingY} ${pendantPaddingX};
  padding-left: 35px;
  margin: 5px 2% 0 0;
  font-weight: 700;
  -moz-border-radius: 100px;
  -webkit-border-radius: 100px;
  -khtml-border-radius: 100px;
  border-radius: 100px;
  background: ${({ rating, theme }) =>
    handleTireLabelRatingBackground2009(rating, theme)};
  color: ${({ rating, theme }) =>
    handleTireLabelRatingColor2009(rating, theme)};
`;

export const TireLabelRatingFuelIcon = styled.div`
  position: absolute;
  float: none;
  top: 50%;
  left: 12px;
  text-alight: center;
  font-size: 17px;
  font-family: "${({ theme }) => theme.font.icon}";
  font-weight: 700;
  margin-top: -8.5px;
  pointer-events: auto;
  color: inherit;
  &::before {
    width: 100%;
    height: 100%;
    content: "\f52f"};
    color: inherit;
  }
`;

const handleTireLabelRatingGripBackground2020 = (rating, theme) => {
  switch (rating) {
    case "A":
      return "#0652dd";
    case "B":
      return "#056ae6";
    case "C":
      return "#047beb";
    case "D":
      return "#0297f4";
    case "E":
      return "#1abbfc";
    default:
      return theme.body.search.list.item.pendant.background;
  }
};

const handleTireLabelRatingGripColor2020 = (rating, theme) => {
  switch (rating) {
    case "A":
      return "rgba(255,255,255,1)";
    case "B":
      return "rgba(255,255,255,1)";
    case "C":
      return "rgba(255,255,255,1)";
    case "D":
      return "rgba(255,255,255,1)";
    case "E":
      return theme.body.search.list.item.pendant.text;
    default:
      return theme.body.search.list.item.pendant.text;
  }
};

export const TireLabelRatingGrip2020 = styled.li`
  font-size: 9em;
  padding: ${pendantPaddingY} ${pendantPaddingX};
  padding-left: 35px;
  margin: 5px 2% 0 0;
  font-weight: 700;
  -moz-border-radius: 100px;
  -webkit-border-radius: 100px;
  -khtml-border-radius: 100px;
  border-radius: 100px;
  background: ${({ rating, theme }) =>
    handleTireLabelRatingGripBackground2020(rating, theme)};
  color: ${({ rating, theme }) =>
    handleTireLabelRatingGripColor2020(rating, theme)};
`;

export const TireLabelRatingGrip2009 = styled.li`
  font-size: 9em;
  padding: ${pendantPaddingY} ${pendantPaddingX};
  padding-left: 35px;
  margin: 5px 2% 0 0;
  font-weight: 700;
  -moz-border-radius: 100px;
  -webkit-border-radius: 100px;
  -khtml-border-radius: 100px;
  border-radius: 100px;
  background: ${({ rating, theme }) =>
    handleTireLabelRatingBackground2009(rating, theme)};
  color: ${({ rating, theme }) =>
    handleTireLabelRatingColor2009(rating, theme)};
`;

export const TireLabelRatingGripIcon = styled.div`
  position: absolute;
  float: none;
  top: 50%;
  left: 12px;
  text-alight: center;
  font-size: 17px;
  font-family: "${({ theme }) => theme.font.icon}";
  font-weight: 700;
  pointer-events: auto;
  margin-top: -8.5px;
  &::before {
    width: 100%;
    height: 100%;
    content: "\f73f"};
      color: inherit;
  }
    color: inherit;
`;

const handleTireLabelRatingNoiseIcon2020 = (rating) => {
  switch (rating) {
    case "A":
      return "f027";
    case "B":
      return "f6a8";
    case "C":
      return "f028";
    default:
      return "f028";
  }
};

const handleTireLabelRatingNoisePadding2020 = (rating) => {
  switch (rating) {
    case "A":
      return "30px";
    case "B":
      return "34px";
    case "C":
      return "36px";
    default:
      return "34px";
  }
};

export const TireLabelRatingNoise2020 = styled.li`
  font-size: 9em;
  padding: ${pendantPaddingY} ${pendantPaddingX};
  padding-left: ${({ rating }) =>
    handleTireLabelRatingNoisePadding2020(rating)};
  margin: 5px 2% 0 0;
  font-weight: 700;
  -moz-border-radius: 100px;
  -webkit-border-radius: 100px;
  -khtml-border-radius: 100px;
  border-radius: 100px;
  background: ${({ theme }) => theme.body.search.list.item.pendant.background};
  color: ${({ theme }) => theme.body.search.list.item.pendant.text};
`;

export const TireLabelRatingNoiseIcon2020 = styled.div`
  position: absolute;
  float: none;
  top: 50%;
  left: 12px;
  text-alight: center;
  font-size: 17px;
  font-family: "${({ theme }) => theme.font.icon}";
  font-weight: 700;
  pointer-events: auto;
  margin-top: -8.5px;
  &::before {
    width: 100%;
    height: 100%;
    content: ${({ rating }) =>
      '"\\' + handleTireLabelRatingNoiseIcon2020(rating) + '"'};
  }
`;

const handleTireLabelRatingNoiseIcon2009 = (rating) => {
  switch (rating) {
    case "1":
      return "f027";
    case "2":
      return "f6a8";
    case "3":
      return "f028";
    default:
      return "f028";
  }
};

const handleTireLabelRatingNoisePadding2009 = (rating) => {
  switch (rating) {
    case "1":
      return "30px";
    case "2":
      return "34px";
    case "3":
      return "36px";
    default:
      return "34px";
  }
};

export const TireLabelRatingNoise2009 = styled.li`
  font-size: 9em;
  padding: ${pendantPaddingY} ${pendantPaddingX};
  padding-left: ${({ rating }) =>
    handleTireLabelRatingNoisePadding2009(rating)};
  margin: 5px 2% 0 0;
  font-weight: 700;
  -moz-border-radius: 100px;
  -webkit-border-radius: 100px;
  -khtml-border-radius: 100px;
  border-radius: 100px;
  background: ${({ theme }) => theme.body.search.list.item.pendant.background};
  color: ${({ theme }) => theme.body.search.list.item.pendant.text};
`;

export const TireLabelRatingNoiseIcon2009 = styled.div`
  position: absolute;
  float: none;
  top: 50%;
  left: 12px;
  text-alight: center;
  font-size: 17px;
  font-family: "${({ theme }) => theme.font.icon}";
  font-weight: 700;
  pointer-events: auto;
  margin-top: -8.5px;
  &::before {
    width: 100%;
    height: 100%;
    content: ${({ rating }) =>
      '"\\' + handleTireLabelRatingNoiseIcon2009(rating) + '"'};
  }
`;
