import React from "react";
import { connect } from "react-redux";

import { Container, Logo, LogoObject, LogoClickable } from "./HeaderStyle";

import logoSVG from "./partner.svg";

import { clearQuery } from "../../../redux/store/search";
import { goHomeStatus } from "../../../redux/store/body";

class Header extends React.Component {
  handleClick = () => {
    if (this.props.bodyShowGoHome) {
      this.props.dispatch(clearQuery());
      this.props.dispatch(goHomeStatus({ goHome: true }));
    }
  };

  render() {
    return (
      <Container>
        <Logo>
          <LogoObject
            type="image/svg+xml"
            data={logoSVG}
            aria-label="PARTNER"
          />
          <LogoClickable
            onClick={this.handleClick}
            snowClickable={this.props.bodyShowGoHome}
          />
        </Logo>
      </Container>
    );
  }
}

export default connect(
  ({ body }) => ({
    bodyShowGoHome: body.showGoHome,
  }),
  null
)(Header);
