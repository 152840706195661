import React from "react";

import {
  Separator,
  Container,
  Element,
  Icon,
  Content,
  Title,
  Description,
} from "./AlertStyle";

class Alert extends React.Component {
  getDescription = () => {
    if (this.props.description) {
      return <Description>{this.props.description}</Description>;
    }
  };

  getIcon = () => {
    if (this.props.icon) {
      return <Icon icon={this.props.icon} />;
    }
  };

  render() {
    //console.log(this.props);

    return (
      <Separator>
        <Container
          alert={this.props.type}
          dismiss={this.props.handleDismiss}
          onClick={() => this.props.handleDismiss(this.props.id)}
        >
          <Element>
            {this.getIcon()}
            <Content icon={this.props.icon}>
              <Title>{this.props.title}</Title>
              {this.getDescription()}
            </Content>
          </Element>
        </Container>
      </Separator>
    );
  }
}

export default Alert;
